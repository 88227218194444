export function user(state = {}, action) {
  switch (action.type) {
    case 'USER':
      return action.payload;
    case 'SET_ROLES':
      state.roles = action.payload;
      return state;
    case 'SET_STATUS':
      state.status = action.payload;
      return state;
    case 'SET_ECREDIT':
      state.ecredit = action.payload;
      return state;

    default:
      return state;
  }
}

export function userIsLoading(state = true, action) {
  switch (action.type) {
    case 'USER_IS_LOADING':
      return action.payload;

    default:
      return state;
  }
}

export function countryInfo(state = true, action) {
  switch (action.type) {
    case 'SET_COUNTRY_INFO':
      return action.payload;

    default:
      return state;
  }
}

export function isLoggedIn(state = false, action) {
  switch (action.type) {
    case 'IS_LOGGED_IN':
      return action.payload;

    default:
      return state;
  }
}
