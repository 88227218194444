import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { LOADER_TYPES } from 'reducers/loader';

import style from './loader.style';

export default function Loader() {
  const loader = useSelector((state) => state?.loader);
  const router = useRouter();
  const bsocial = router.pathname.match('/shop/');
  return (
    <>
      <div
        style={{
          height: '100%',
          width: '100%',
          position: 'fixed',
          top: 0,
          zIndex: loader?.isLoading ? 1000 : -1,
          opacity: loader?.isLoading ? 1 : 0,
          backgroundColor:
            loader?.loaderType === LOADER_TYPES.RAISED
              ? 'var(--background-utils)'
              : 'rgba(0, 94, 255, .6)',
        }}
      >
        <div className="logo-wrapper">
          <div
            className={`${bsocial ? 'bsocial-loader' : 'bdroppy-loader'} logo`}
          >
            <div></div>
            <div></div>
            <div></div>
            <img
              className="animate-flicker"
              src={
                bsocial
                  ? '/images/channel/logo/logo-social.svg'
                  : '/BDroppy-logo-small.svg'
              }
              alt="bdroppy-logo"
            />
          </div>
        </div>
      </div>
      <style jsx>{style}</style>
    </>
  );
}
