import message from 'components/utils/message';
import { addProductReservationCart } from 'actions/product';
function previousRoute(payload) {
  return { type: 'SET_PREVIOUS_ROUTE', payload };
}
function isConfirmed(payload) {
  return { type: 'IS_CONFIRMED', payload };
}
function isEnabled(payload) {
  return { type: 'IS_ENABLED', payload };
}
function isMinimized(payload) {
  return { type: 'IS_MINIMIZED', payload };
}
function isClosed(payload) {
  return { type: 'IS_CLOSED', payload };
}

function isActiveList(payload) {
  return { type: 'SET_IS_ACTIVE_LIST', payload };
}

function isSource(payload) {
  return { type: 'SET_SOURCE', payload };
}

function isAddToCart(payload) {
  return { type: 'ADD_TO_CART', payload };
}

export function cartProducts(products) {
  return { type: 'CART_PRODUCTS', payload: products };
}

export function cartProductsVetrina(products) {
  return { type: 'CART_PRODUCTS_VETRINA', payload: products };
}

export function setRedirectUrl(payload) {
  return { type: 'SET_REDIRECT_URL', payload };
}

export function setChannelcode(payload) {
  return { type: 'SET_CHANNELCODE', payload };
}

export function addProductCart(product, cartType = 'cartProducts') {
  return (dispatch, getState) => {
    const products = getState()[cartType];
    let index;
    if (cartType === 'cartProducts') {
      index = products.findIndex((el) => el.model?.id === product?.model?.id);
    } else {
      index = products.findIndex(
        (el) => el.model?.refId === product?.model?.refId
      );
    }

    if (index >= 0) {
      const quantity = products[index].quantity + product.quantity;
      if (quantity > product.model.availability) {
        message.warning('Inventory is not enough');
      } else {
        products[index].quantity = quantity;
        if (cartType === 'cartProducts') {
          dispatch(cartProducts([...products]));
          dispatch(
            addProductReservationCart(
              products.map((product) => ({
                model: product?.model?.id,
                quantity: product?.quantity,
              }))
            )
          );
        } else {
          dispatch(cartProductsVetrina([...products]));
        }
      }
    } else {
      if (cartType === 'cartProducts') {
        let _products = [...products, product];
        dispatch(cartProducts(_products));
        dispatch(
          addProductReservationCart(
            _products.map((product) => ({
              model: product?.model?.id,
              quantity: product?.quantity,
            }))
          )
        );
      } else {
        dispatch(cartProductsVetrina([...products, product]));
      }
    }
  };
}

export function clearCart(cartType = 'cartProducts') {
  return (dispatch) => {
    if (cartType === 'cartProducts') {
      dispatch(addProductReservationCart([]));
      dispatch(cartProducts([]));
    } else {
      dispatch(cartProductsVetrina([]));
    }
  };
}

export function removeProductCart(product, cartType = 'cartProducts') {
  return (dispatch, getState) => {
    let newProducts;
    if (cartType === 'cartProducts') {
      newProducts = getState()[cartType].filter(
        (el) => el?.model?.id !== product?.model?.id
      );
    } else {
      newProducts = getState()[cartType].filter(
        (el) => el?.model?.refId !== product?.model?.refId
      );
    }
    if (cartType === 'cartProducts') {
      dispatch(
        addProductReservationCart(
          newProducts.map((product) => ({
            model: product?.model?.id,
            quantity: product?.quantity,
          }))
        )
      );
      dispatch(cartProducts(newProducts));
    } else {
      dispatch(cartProductsVetrina(newProducts));
    }
  };
}

export function setAddToCart(isActive) {
  return (dispatch) => {
    dispatch(
      isAddToCart({
        add_to_cart: isActive,
      })
    );
  };
}

export function setIsActiveList(isActive) {
  return (dispatch) => {
    dispatch(
      isActiveList({
        isActiveList: isActive,
      })
    );
  };
}

export function setIsConfirmed(email) {
  return (dispatch) => {
    dispatch(
      isConfirmed({
        show_confirmation_email_message: true,
        confirmation_email: email,
      })
    );
  };
}

export function closeConfirmedMessage() {
  return (dispatch) => {
    dispatch(
      isConfirmed({
        show_confirmation_email_message: false,
        confirmation_email: null,
      })
    );
  };
}

export function closePopup() {
  return (dispatch) => {
    dispatch(
      isClosed({
        close_popup: true,
      })
    );
  };
}

export function setSource(source) {
  return (dispatch) => {
    dispatch(
      isSource({
        source,
      })
    );
  };
}

export function setPublicToken(publicToken) {
  return (dispatch) =>
    dispatch({ type: 'SET_PUBLIC_TOKEN', payload: { publicToken } });
}

/**
 * Set WIX token, used during app installation
 * https://dev.wix.com/api/rest/getting-started/authentication#getting-started_authentication_step-1-user-installs-your-app
 *
 * @param wixToken
 * @returns {function(*): *}
 */
export function setWixToken(wixToken) {
  return (dispatch) =>
    dispatch({ type: 'SET_WIX_TOKEN', payload: { wixToken } });
}

export function closeHowToStart() {
  return (dispatch) => {
    dispatch(isEnabled(false));
  };
}

export function minimizeSidebar() {
  return (dispatch) => {
    dispatch(isMinimized(true));
  };
}
export function maximizeSidebar() {
  return (dispatch) => {
    dispatch(isMinimized(false));
  };
}

export function setPreviousRoute(payload) {
  return (dispatch) => {
    dispatch(previousRoute(payload));
  };
}
