import message from 'components/utils/message';
import request from 'tools/request';
import { getCountryById } from 'tools/utils';

export function setAddresses(payload) {
  return { type: 'ADDRESSES', payload };
}

export function getAddresses(onSuccess = () => {}, onError = () => {}) {
  return (dispatch) => {
    request('/api/address')
      .then((response) => onSuccess(response.data))
      .catch((err) => {
        console.log(err);
        onError();
      })
      .finally(() => {});
  };
}
export function getAddressesFilter(params = {}, onSuccess = () => {}) {
  return (dispatch) => {
    request('/api/address/filter', {
      params,
      // params: { q: { user: '61c33c3b3be8bd2f7aedc588' } },
    })
      .then((response) => onSuccess(response.data))
      .catch((err) => console.log(err));
  };
}

function searchedAddressesByEmail(payload) {
  return { type: 'SEARCHED_ADDRESSES_BY_EMAIL', payload };
}

export function searchAddressesByEmail(value) {
  return (dispatch) => {
    if (value === '') {
      dispatch(searchedAddressesByEmail([]));
    } else {
      request(`/api/orders/customers?q=${value}`)
        .then((response) => {
          dispatch(searchedAddressesByEmail(response.data));
        })
        .catch((err) => message.error("Can't load addresses"));
    }
  };
}

export function setNewAddress(newRecipient) {
  return (dispatch) => {
    request('/api/address', { method: 'POST', data: newRecipient })
      .then(() =>
        dispatch(getAddresses((data) => dispatch(setAddresses(data))))
      )
      .catch((err) => console.log(err));
  };
}

export function updateAddress(addressId, updatedRecipient) {
  return (dispatch) => {
    request(`/api/address/${addressId}`, {
      method: 'PUT',
      data: updatedRecipient,
    })
      .then(() =>
        dispatch(getAddresses((data) => dispatch(setAddresses(data))))
      )
      .catch((err) => console.log(err));
  };
}

export function deleteAddress(addressId) {
  return (dispatch) => {
    request(`/api/address/${addressId}`, { method: 'DELETE' })
      .then(() =>
        dispatch(getAddresses((data) => dispatch(setAddresses(data))))
      )
      .catch((err) => console.log(err));
  };
}
export function checkVat(
  countrycode,
  vat,
  onSuccess = () => {},
  onError = () => {}
) {
  return () => {
    request(`/api/address/country/${countrycode}/vatnumber/${vat}/validate-vat`)
      .then((response) => onSuccess(response?.data))
      .catch(onError);
  };
}

export function validateVat(
  addressId,
  onSuccess = () => {},
  onError = () => {}
) {
  return () => {
    request(`/api/address/${addressId}/validate-vat`)
      .then((response) => onSuccess(response?.data))
      .catch(onError);
  };
}

export function transformRecipient(address) {
  const country = getCountryById(address?.country?._id);
  return {
    ...address,
    _id: address?._id,
    recipient: address?.addressee,
    address: {
      streetName: address?.street,
      zip: address?.cap,
      city: address?.city,
      province: address?.prov,
      countrycode: country?.countrycode,
      country: country?._id && country?.country ? country._id : null,
    },
    phone: {
      prefix: `${/^\d+$/.test(address?.celPrefix) ? '+' : ''}${
        address?.celPrefix
      }`,
      number: address?.cel,
    },
  };
}
