export function orders(state = { items: [] }, action) {
  switch (action.type) {
    case 'ORDERS':
      return action.payload;

    default:
      return state;
  }
}

export function ordersIsLoading(state = true, action) {
  switch (action.type) {
    case 'ORDERS_IS_LOADING':
      return action.payload;

    default:
      return state;
  }
}

export function order(state = {}, action) {
  switch (action.type) {
    case 'ORDER':
      return action.payload;
    default:
      return state;
  }
}

export function orderTotal(state = {}, action) {
  switch (action.type) {
    case 'ORDER_TOTAL':
      return action.payload;
    default:
      return state;
  }
}

export function orderIsLoading(state = true, action) {
  switch (action.type) {
    case 'ORDER_IS_LOADING':
      return action.payload;

    default:
      return state;
  }
}

export function orderProducts(state = [], action) {
  switch (action.type) {
    case 'ORDER_PRODUCTS':
      return action.payload;

    default:
      return state;
  }
}

export function orderRecipient(state = {}, action) {
  switch (action.type) {
    case 'ORDER_RECIPIENT':
      return action.payload;

    default:
      return state;
  }
}

export function orderStep(state = 1, action) {
  switch (action.type) {
    case 'ORDER_STEP':
      return action.payload;

    default:
      return state;
  }
}

export function orderBill(state = {}, action) {
  switch (action.type) {
    case 'ORDER_BILL':
      return action.payload;

    default:
      return state;
  }
}

export function orderShipping(state = null, action) {
  switch (action.type) {
    case 'ORDER_SHIPPING':
      return action.payload;

    default:
      return state;
  }
}

export function orderNote(state = '', action) {
  switch (action.type) {
    case 'ORDER_NOTE':
      return action.payload;

    default:
      return state;
  }
}

export function orderPayment(state = [], action) {
  switch (action.type) {
    case 'ORDER_PAYMENT':
      return action.payload;
    default:
      return state;
  }
}

export function ordersUnpaid(state = { items: [] }, action) {
  switch (action.type) {
    case 'ORDERS_UNPAID':
      return action.payload;

    default:
      return state;
  }
}
