const translations = () => ({
  home: {
    hello: 'Hello',
    videoTitle: 'BDroppy in 30 seconds',
    whatToDo: 'How it works',
    startShopping: 'Start shopping online on BDroppy now',
    seeProduct: 'See products',
    viewAll: 'View all',
    titleRoleUSer: 'Hi',
    faqAndTutorial: 'FAq and tutorial',
    apartFrom:
      'Apart from using BDroppy as a platform to start or boost your online business, you also have the option to shop for yourself, safely and conveniently!',
    becomeReseller:
      'Become a Reseller: Start Making Money In Three Simple Steps',
    seeCatalog: 'Category',
    cardText1: 'The easiest way to sell designer',
    cardText2: 'fashion in dropshipping',
    cta: 'Choose your plan',
    discoverTitle: 'Discovering BDroppy',
    discoverDescription:
      'Watch our webinar and find out how easy it is to use BDroppy',
    bookCall: 'Book a free call',
    weeklyArrivals:
      'New weekly arrivals for your dropshipping business: Gucci, Harmont & Blaine, Trussardi, Napapijri',
    readArticle: 'Read the article',
    emailConfirm: 'You have to confirm email in order to activate account',
    checkEmail: 'Check your email or click to resend.',
    resendEmail: 'Resend email',
    emailSent: 'Email sent correctly check your email please',
    role: 'Free role',
    step1: 'Step 1',
    step2: 'Step 2',
    step3: 'Step 3',
    confirmEmail: 'Confirm email',
    close: 'Close',
    limitedTimeOffer: 'Limited time offer',
    expired: 'Expired in',
    yourCashback: 'Cashback balance',
    welcome: 'Welcome on BDroppy',
    discount: 'of discount',
    only72h: 'Solo per 72h',
    videos: {
      title: 'Get an overview',
      title2: 'How to create your first list',
      title3: 'How to chose a channel',
    },
    coach: {
      videoTitle: 'Ask the coach',
      videoDescription:
        'Do you have any doubts or questions about Amazon FBA? This webinar is for you!',
      newEpisode: 'New episode',
      href: 'https://www.youtube.com/playlist?list=PLx_gWhKuj-EO7il_OPJwC82Loq85bOfI7',
      cta: 'See all video',
    },
    howToStartTitle: 'How to start',
    howToStartFree: {
      tab: 'Your role: Free',
      title: 'Cosa puoi fare? ',
      subtitle:
        'Fino a 5 ordini manuali al mese inclusi, acquisti a prezzi di ingrosso, senza costi di servizio!',
      li_1: '1 - Scegli i prodotti che vuoi acquistare e vendere ',
      li_2: '2 - Mostrali ai tuoi amici e conoscenti',
      li_3: "3 - concludi l'ordine su BDroppy",
      li_4: '4 - guadagna dalle tue vendite senza costi di transazione',
      li_5: '4 - guadagna dalle tue vendite senza costi di transazione',
      cta: 'Vai al catalogo',
    },
    howToStart1: {
      tab: 'Select products',
      title:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
      subtitle:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
      cta: 'Select products',
    },
    howToStart2: {
      tab: 'Create import list',
      title:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
      subtitle:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
      cta: 'Create your list',
    },
    howToStart3: {
      tab: 'Choose a channel',
      title:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
      subtitle:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
      cta: 'Choose your channel',
    },
    dismiss: 'Dismiss',
    blog: 'Latest from the blog',
    tutorial: 'FAQ e Tutorials',
    tutorialDescription:
      'Find out how to best use BDroppy thanks to our insights.',
    tutorialImg:
      'https://bdroppy.com/wp-content/uploads/2022/02/faq-and-tutorials.jpg',
    viewTutorial: 'View tutorial',
    bestSellers: 'Best sellers',
    category_1: 'Clothing',
    category_2: 'Bags',
    category_3: 'Shoes',
    category_4: 'Accessories',
    category_5: 'Underwear',
    category_6: 'Bellezza',
    stepFree_1: '1. Select products',
    stepFree_2: '2. Choose your plan',
    stepFree_3: '3. You sell, we ship',
    goPlan: 'Vai ai piani',
    about: 'Chi siamo',
    myLists: 'My list',
    imperdibili: 'Unmissable',
    bestSeller: 'Best sellers',
    blackFriday: 'Black friday',
    reseller: 'Become reseller',
    affiliation: 'Become affiliated',
    resellerText:
      'Fai crescere il tuo business online rivendendo [resellerText2], senza rischi di magazzino',
    resellerText2: 'grandi marchi moda',
    affiliationText:
      'Diventa un partner affiliato e [affiliationText2] su ogni acquisto del cliente da te segnalato',
    affiliationText2: 'guadagna fino all’ X%',
  },
  header: {
    menu: 'Menu',
    resellerArea: 'Reseller area',
    subheader: {
      man: 'Man',
      woman: 'Donna',
      accessories: 'Accessories',
      bags: 'Bags',
      clothing: 'Clothing',
      underwear: 'Underwear',
      beauty: 'Beauty',
      shoes: 'Shoes',
      brands: 'Brands',
      reseller: 'Become a reseller',
      discoverPlan: 'Discover plan',
      cashback: "You've reached the max cash amount of [cashback_2]",
      cashback_2: 'activate the plan social to earn more cashback',
      youEarn: 'You already earn',
    },
    headerPublic: {
      text1: '[highlight] IMMEDIATO, anche sugli acquisti [highlight2]',
      highlight1: '10% di cashback',
      highlight2: 'dei tuoi amici!',
      text2: 'E sul primo acquisto ricevi [highlight]',
      highlight3: '10€ di bonus',
      CTA: 'Scopri il catalogo',
    },
  },
  footer: {
    company: 'Company',
    blog: 'Blog',
    about: 'About',
    resources: 'Resources',
    shippingFees: 'Shipping fees',
    images: 'Marketing Videos & Images',
    pricing: 'Pricing',
    partners: 'Area partners & Business',
    affiliation: 'Affiliation',
    reseller: 'Reseller',
    onlyOne: 'Be the only one',
    help: 'Help',
    faq: 'Faq & tutorial',
    tutorial: 'Tutorial',
    shipping: 'Shipped',
    paymentMethod: 'Payment method',
    privacy: 'Privacy policy',
    terms: 'Terms and conditions',
    bdroppy: '© BDroppy 2023',
    contactUs: 'Contact us',
    cashback: 'Cashback',
    howToReturn: 'How to ask for a return',
  },
  promo: {
    timer: {
      seconds: 'Seconds',
      days: 'Days',
      hours: 'Hours',
      minutes: 'Minutes',
    },
  },
  notifications: {
    title: 'Notifications',
    noOrderToPay: 'You have 0 unpaid orders',
    subtitle: 'You have some order to pay',
    viewAll: 'View all',
    payNow: 'Pay now',
    date: 'Date',
    total: 'Total',
  },
  pricing: {
    gratis: 'Gratis',
    activePlan: 'Plan already activate',
    tutorialBsocial: 'Guarda il nostro tutorial',
    downloadExampleFile: 'Download the example file',
    bsocialPromo: 'Solo per pochi giorni',
    title: 'Our Pricing Plans',
    news: 'New',
    newPlan: 'Il più economico',
    wixInfo:
      'For the payment you will be redirect to wix.com, please select a plan',
    tab1: 'Dropshipping plans',
    tab2: 'Do you want a turnkey site?',
    tab3: 'Vuoi vendere moda SENZA bisogno di un ecommerce?',
    monthly: 'Monthly',
    yearly: 'Yearly',
    mostPopular: 'Most popular',
    cashback: 'Cashback 10%',
    showMore: 'Show details',
    findMore: 'Discover more',
    seeTemplate: 'See template',
    buy: 'Buy',
    billedAnnually: 'Billed annually',
    month: 'month',
    year: 'year',
    startFreeTrial: 'Start free trial',
    startNow: 'Start now',
    save: 'You are saving',
    descriptionPlan: '*description plan',
    descriptionEasy:
      'Best for new ecommerce businesses with occasional in-person sales',
    descriptionBasic:
      'Best for new ecommerce businesses with occasional in-person sales',
    descriptionPlus: 'Best for growing businesses selling online or in-store',
    descriptionEnterprise:
      'Best for new ecommerce businesses with occasional in-person sales',
    descriptionShopify:
      'Best for new ecommerce businesses with occasional in-person sales',
    descriptionEasySite: 'Piano dropshipping Basic in regalo per un anno!',
    descriptionStarter: 'Ideal for starting your online business in fashion',
    descriptionBSocial: 'Condividi e vendi sui tuoi social network preferiti',
    descriptionLight:
      'Esporta tutto il catalogo in formato xls, csv, xml, json e crea i tuoi ordini manualmente',
    drop: {
      title: 'Grow your dropshipping business',
      paragraph:
        'Choose how to integrate the BDroppy catalog into your ecommerce or marketplace',
    },
    createCatalog:
      "Looks like you don't have a list, create your first list to start selling on Wix",
    createCatalogCTA: 'Create list',
    turnkey: {
      title: 'Or choose a turnkey website',
      paragraph:
        'We create your ready-to-use e-commerce for you, with integrated fashion catalog and dropshipping service included',
    },
    bsocial: {
      title: 'Vuoi vendere moda SENZA bisogno di un ecommerce?',
      paragraph:
        'Condividi e vendi sui tuoi social network preferiti in un click',
      activationBsocial: 'You can activate BSocial directly from your channels',
      socialVideoTutorial: 'https://youtu.be/PEWf87rQfKc',
    },
    infoBanner: {
      title:
        'Would you like to receive a custom quote or do you want to use our API?',
      subTitle: 'Book a call with one of our representatives now',
      cta: 'Book a call',
      shopifyTitle: 'Vendi su Shopify?',
      shopifySubtitle:
        'Questo è il piano che devi attivare! Il collegamento avverrà direttamente con il fornitore',
      turnkeyTitle:
        'Hai un budget da dedicare ad un progetto personalizzato e su misura per te? ',
      turnkeySubTitle:
        'Profilati nel form sottostante e una nostra esperta ti ricontatterà',
      turnkeyCta: 'Compila il modulo',
    },
    discount: {
      discountEasy: '20%',
      discountBasic: '40%',
      discountPlus: '60%',
      discountEnterprise: '25%',
      discountStarter: '30%',
      discountEasySite: '-40%',
      discountBsocial: 'Up to 40% discount',
      staiRisparmiandoLight: 'you are saving',
      staiRisparmiandoBasic: 'you are saving',
      staiRisparmiandoPlus: 'you are saving',
      staiRisparmiandoBsocial: 'you are saving',
      mesiInRegaloBasic: 'free months',
      mesiInRegaloPlus: 'free months',
      mesiInRegaloLight: 'free months',
      mesiInRegaloBsocial: 'free months',
      discountLight: '-10%',
      yearly: 'Risparmia fino a 7 mesi',
      monthDiscount: 'Up to 40% discount',
      limitedTimeOffer: 'LIMITED TIME OFFER',
    },
    modal: {
      shopifyTitle:
        'Install the Brandssync app for Shopify and start your free trial',
      subtitle:
        '*Enter your Shopify store link, you will be redirected directly to the Shopify App Store',
      question: 'Don’t have a Shopify store yet? ',
      createNow: 'Create it now',
      text: 'With BrandsSync you can import Brandsdistribution’s fashion catalog into your Shopify store and sell the best fashion brands in dropshipping.',
      visitApp: 'Visit the App Store',
      alert: 'Insert valid shop name',
      template: 'Template',
      templateDescription:
        'Watch our demos and choose the look you want for your web store.',
      viewTemplate: 'View template',
      upgrade: 'Upgrade--->if you want a turnkey lose your channel',
      downgrade: 'Downgrade---> you will lose catalog',
      noTurnkey: 'noTurnkey---> you will lose turnkey',
      changePlan: 'Change plan',
      goBack: 'Go back',
      confirm: 'Continue',
      submit: 'Submit',
    },
    shopify: {
      title: 'Shopify plan',
      listTitle:
        'HERE ARE THE ADVANTAGES OF THE EXCLUSIVE BANDSSYNC APP FOR SHOPIFY',
      features1: 'A catalog with over 100 top fashion brands',
      features2: '100% original branded fashion products',
      features3: 'Fast and reliable shipping from Italy',
      features4: 'Professional photos for your marketing campaigns',
      features5: 'Multilingual and multicurrency management',
      features6: 'Automatic synchronization of orders and quantities',
      howitWorksTitle1: 'DOWNLOAD THE APP',
      howitworks1:
        'Download BrandsSync and set up your account. Try it for free for 15 days!',
      howitWorksTitle2: 'IMPORT THE CATALOGUE',
      howitworks2:
        'Import the entire catalogue or select only the categories or products you want to sell in your Shopify store.',
      howitWorksTitle3: 'SET THE MARK UP',
      howitworks3:
        'Set the price and the mark up of your products and decide how much you earn from your sales.',
      howitWorksTitle4: 'SELL THE PRODUCTS',
      howitworks4:
        'Start selling designer clothing and fashion accessories without further investments in warehousing and logistics.',
      howitWorksTitle5: 'PAY THE ORDERS',
      howitworks5:
        'Once you have received an order on your Shopify store, access Brandsdistribution to proceed with the payment.',
      howitWorksTitle6: 'RELAX AND EARN',
      howitworks6:
        'Brandsdistribution ships the products directly to your customers through international couriers.',
      howitWorksTitleSection: 'HOW IT WORKS',
      needHelp: 'Do you need help?',
      pleaseNote:
        'PLEASE NOTE that you can use your BDroppy username and password to access Brandsdistribution.com',
    },
    faq: {
      title: 'Most common questions',
      question_1:
        'Do I have to sell the same products in-store as I do online?',
      answer_1:
        'No, you can choose exactly what products to sell online, in-store, or both.',
      question_2:
        'Do I have to sell the same products in-store as I do online?',
      answer_2:
        'No, you can choose exactly what products to sell online, in-store, or both.',
      question_3:
        'Do I have to sell the same products in-store as I do online?',
      answer_3:
        'No, you can choose exactly what products to sell online, in-store, or both.',
      question_4:
        'Do I have to sell the same products in-store as I do online?',
      answer_4:
        'No, you can choose exactly what products to sell online, in-store, or both.',
      question_bsocial:
        'Do I have to sell the same products in-store as I do online?',
      answer_bsocial:
        'No, you can choose exactly what products to sell online, in-store, or both.',
    },
  },
  orders: {
    table: {
      actions: 'Actions',
      status: 'Status',
      statuses: {
        0: 'Pending',
        1: 'Payment waiting',
        2: 'Confirmed',
        3: 'Dispatched',
        5: 'Booked',
        2000: 'Failed',
        2001: 'No available',
        2002: 'Unknown',
        2003: 'API error',
        3001: 'Working on',
        3002: 'Ready',
        alternativeLabel_0: 'Pending approval',
        alternativeLabel_2000: 'Canceled',
      },
      carrier: 'Carrier',
      carrierTracking: 'Tracking',
      shippingTracking: 'Shipping tracking',
      customerEmail: 'Customer email',
      dateOrder: 'Date order',
      externalRef: 'External ref',
      supplierId: 'Supplier id',
      orderId: 'Order ID',
      bdroppyOrderId: 'BDroppy Order ID',
      cost: 'Cost',
      dispatchedTo: 'Dispatched to',
      lastUpdate: 'Last update',
      title: 'Order list',
      warning:
        'WARNING: On November 29th, 2022 from 7 a.m. to 7:30 a.m. (CET) a.m., payments will not be enabled due to extraordinary platform maintenance.',
    },
    new: {
      minOrder400: 'Min order is 400€',
      back: 'Back',
      selectBySku: 'Select the product by SKU',
      cost: 'Cost',
      total: 'Total',
      color: 'Color',
      size: 'Size',
      confirm: 'Confirm',
      quantity: 'Quantity',
      availability: 'Availability',
      add: 'Add',
      shipToCustomer: 'Ship to customer',
      preFill: 'Pre-fill with billing address',
      shipToMe: 'Ship to me',
      shipping: 'Shipping address',
      sameAddress: 'Use the same address for shipping',
      selectEmail: 'Select recipient email',
      productSelected: 'Product selected',
      addNewCustomer: 'Add New Customer',
      newCustomer: {
        email: 'Email',
        edit: 'Edit address',
        new: 'Add new address',
        name: 'Name',
        taxId: 'Tax id',
        sdi: 'SDI',
        cf: 'Fiscal code',
        pec: 'pec',
        companyName: 'Company Name',
        street: 'Street and house number',
        apartment: 'Apartment. suite, etc. (optional)',
        postalCode: 'Postal Code',
        city: 'City',
        province: 'Province',
        country: 'Country',
        prefix: 'Prefix code',
        phone: 'Phone number',
        disableVatNumber: 'Do you want to not receive invoices?',
        cancel: 'Cancel',
        save: 'Save',
        check: 'Verify on VIES',
      },
      recipientSelected: 'Recipient selected',
      selectRecipient: 'Select recipient',
      selectShipping: 'Select your Shipping type',
      freeShipping: 'Free shipping',
      insertNote: 'Insert your note here',
      saveAndPay: 'Save and pay order',
      checkout: 'Checkout',
      nextStep: 'Next step',
      customerInfo: 'Customer info',
      shippingAddress: 'Shipping info',
      productSummary: 'Product summary',
      orderSummary: 'Order summary',
      warning: {
        selectProduct: 'Please first select products',
        selectRecipient: 'Please select recipient',
        selectShipping: 'Please select shipping type',
      },
      weightWarning:
        'The weight of the shipment exceeds the maximum weight that can be shipped to this country, reduce the number of items.',
      messageCompleteOrder:
        'Please select the quantity and size of your product and add it to cart',
      stepbar_1: 'Address',
      stepbar_2: 'Shipping',
      stepbar_3: 'Payment',
      remove: 'Remove',
    },
    edit: {
      cost: 'Cost',
      size: 'Size',
      color: 'Color',
      ecredit: 'Ecredit',
      welcomeDiscount: 'Welcome discount',
      totalToPay: 'Total to pay',
      cashback: 'Cashback',
      vat: 'VAT',
      totalTaxable: 'Total taxable (no tax)',
      productsTaxable: 'Total taxable (no tax)',
      productsTotal: 'Total products',
      total: 'Total',
      quantity: 'Quantity',
      orderDate: 'Order Date',
      shipping: 'Shipping',
      shippingTaxable: 'Shipping',
      shippingTotal: 'Total shipping',
      freeShipping: 'Free shipping',
      ID: 'ID',
      customerInfo: 'Customer info',
      shippingTruck: 'Shipping',
      orderTracking: 'Order tracking',
      saveAndPayLater: 'Save and pay later',
      checkout: 'Checkout',
      edit: 'Edit',
      productSummary: 'Product summary',
      availability: 'Availability',
      messageError: "You can't save an empty order",
      checkoutError: 'The order is empty',
      tabProduct: 'Products',
      tabRecipient: 'Recipient info',
      tabShipping: 'Shipping type',
      tabShipToMe: 'Ship to me',
      tabShipToCustomer: 'Ship to customer',
      empty: 'Cart is empty',
      emptyParagraph: 'Look like you have no items in your order.',
      backToOrder: 'Back to order',
      delete: 'Delete',
      days: 'In working days',
      selectLanguages: 'Select languages',
      minOrder400: 'Min order is 400€',
      updateOrderMessage:
        'Clicking save and pay later or checkout you will update the order with the available quantities',
    },
    detail: {
      cost: 'Cost',
      size: 'Size',
      color: 'Color',
      total: 'Total',
      quantity: 'Quantity',
      ID: 'ID',
      orderDate: 'Order Date',
      customerInfo: 'Customer info',
      shippingTruck: 'Shipping truck',
      orderTracking: 'Order tracking',
      orderSummary: 'Order summary',
      productSummary: 'Product summary',
      recipient: 'Recipient',
      shipping: 'Shipping',
      tabProduct: 'Products',
      tabRecipient: 'Recipient info',
      tabShipping: 'Shipping type',
      tabShipToMe: 'Ship to me',
      tabShipToCustomer: 'Ship to customer',
      days: 'In working days',
    },
    preview: {
      customer: 'Customer info',
      totalTaxable: 'Total taxable (no tax)',
      shipping: 'Shipping',
      vat: 'VAT',
      items: 'Items',
      pc: 'pc',
      cost: 'Cost',
      code: 'Code',
      color: 'Color',
      size: 'Size',
      quantity: 'Quantity',
      total: 'Total',
      ecredit: 'Ecredit',
      payOrder: 'Pay order',
      missing: 'Missing items',
    },
    action: {
      pay: 'Pay order',
      force: 'Force confirm order',
      delete: 'Delete order',
      edit: 'Edit',
      detail: 'See detail',
      return: 'Return request',
      newOrder: 'New order',
    },
    list: {
      noData: 'Sorry no data found',
      empty: 'Your order list is empty',
      cta: 'Create your first order',
      createFirstOrder: 'Create your first order',
      freeUser:
        'Do you want to start immediately with your purchases without activating any plan?',
      order400: 'Browse the catalog and place your order (minimum order 400 €)',
      selected: 'selected',
      differentVatRules:
        "You can't pay orders with different vat at the moment.",
      filter: {
        title: 'Filter',
        status: 'Status',
        addToImportList: 'Add to import list',
        addTo: 'Add to',
        shippingCountry: 'Shipping country',
        customerEmail: 'Customer email',
        extRef: 'Ext. Ref.',
        trackingCode: 'Tracking code',
        transRef: 'Trans. Ref.',
        orderId: 'Order id',
        sku: 'Sku',
        applyFilter: 'Apply filters',
        applied: 'Filters applied',
        startDate: 'Start date',
        endDate: 'End date',
        catalogs: 'Catalogs',
        price: 'Price',
        sort: {
          title: 'Sort',
          new: 'Last orders',
          old: 'Older orders',
        },
        product: {
          category: 'Category',
          subCategory: 'Subcategory',
          brands: 'Brands',
          gender: 'Gender',
          season: 'Season',
          availability: 'Availability',
          priceMin: 'Min price',
          priceMax: 'Max price',
          promo: 'Show only product in promo',
          onlyFavourites: 'Show only product on your store',
          priceType: 'Price type',
          promotion: 'Promotions',
          search: 'Search',
          moreFilters: 'More filters',
          onlyOutOfStock: 'Show only products out of stock',
        },
        textSearch: 'Search by customer email',
        reset: 'Reset',
        resetAll: 'Reset all',
      },
      makeReturn: 'You are about to make a return for the order',
      confirmOrder: 'You are about to confirm order',
      sendWithCod: 'Send order with cash on delivery',
    },
    actionbar: {
      filter: 'Filters',
      customerEmail: 'Customer email',
      actions: 'Actions',
      export: 'Export',
      print: 'Print',
      pay: 'Pay',
      selected: 'selected',
    },
    tab: {
      toPay: 'To pay',
      all: 'All',
    },
    update: {
      title: 'Update orders',
      goOrder: 'Go back to order',
      warningMessage:
        'There are some orders that needs your confirmation before payment',
      ordersUpdated:
        'All orders are updated, you are for payment, click proceed to checkout',
      order: 'Order',
      retry: 'Retry',
      confirm: 'Confirm',
      remove: 'Delete',
      checkout: 'Proceed to checkout',
      messageMissing:
        'The following products may have fewer quantities or may no longer be available',
      modalTitleRetry: 'Is again available? ',
      modalSubtitleRetry:
        'If you click on retry will you see if products are again available. If products are no availabe the order will be delete automatically',
      modalTitleConfirm: 'Confirm your order',
      modalSubtitleConfirm:
        "If you click on confirm you will confirm the order as it is now. Order's products availability can be changed, if you want to be sure before confirm click on Retry.",
      modalTitleDelete: 'Are you sure you want to delete your order?',
      modalSubtitleDelete:
        "If you click on remove, your order will change status to 'Canceled' and wil be removed from payement and deleted from your order list",
    },
  },
  ordersReturn: {
    title: 'Return request',
    viewDetail: 'View details',
    list: {
      empty: 'Your return request list is empty',
      emptyCta: 'Go to order',
      info: 'Here you can find your return request and see the status of your request',
      table: {
        dateLabel: 'Request Date',
        orderId: 'Order ID',
        email: 'Customer email',
        status: 'Request status',
        code: 'Return Code',
        file: 'File',
        error: 'Error',
      },
      previewTable: {
        product: 'Product',
        reason: 'Reason',
        file: 'File attached',
      },
      tag: {
        pending: 'Request sent',
        processing: 'Authorized',
        received: 'Received',
        not_received: 'Not received',
      },
      filter: {
        title: 'Filter',
        status: 'Status',
        addToImportList: 'Add to import list',
        addTo: 'Add to',
        shippingCountry: 'Shipping country',
        customerEmail: 'Customer email',
        extRef: 'Ext. Ref.',
        trackingCode: 'Tracking code',
        transRef: 'Trans. Ref.',
        orderId: 'Order id',
        sku: 'Sku',
        applyFilter: 'Apply filters',
        startDate: 'Start date',
        endDate: 'End date',
        catalogs: 'Catalogs',
        search: 'Search',
        sort: {
          title: 'Sort',
          new: 'Last orders',
          old: 'Older orders',
        },
      },
    },
    request: {
      selectSentense: 'Select the products you want for return',
      orderId: 'Order ID',
      size: 'Size',
      sku: 'Sku',
      cost: 'Cost',
      color: 'Color',
      quantity: 'Quantity',
      countLabel: 'Count to return',
      countPlaceholder: 'Count',
      reasonLabel: 'Reason of return',
      reasonPlaceholder: 'Reason',
      noteLabel: 'Note',
      notePlaceholder: 'insert a note',
      imageLabel: 'Image',
      submitRequest: 'Submit request',
      modalTitle: 'Request saved',
      modalSubscription: 'Return request have been submited',
      selectCount: 'Please select count.',
      noProductToReturn: 'There is no product to return.',
      insertNote: 'Please insert note',
      insertNoteAndImage: 'Please insert note and image',
      addQuantity: 'Add quantity',
      delete: 'Delete',
      messageError: 'There is no product to return in this order!',
      reasons: {
        0: 'Other',
        1: 'Bought by mistake',
        2: 'The product is damaged',
        3: 'The product received is not what I requested',
        4: 'The product is malfunctioning',
        5: '',
        6: 'The product is too large / small',
        7: 'I don’t like the product',
        8: 'The product is missing',
      },
    },
    preview: {
      orderInfo: 'Order info',
      id: 'ID',
      orderDate: 'Order date',
      items: 'Item',
      returnRequest: 'Return request',
    },
  },
  payment: {
    title: 'Payment',
    orderQuantity: 'Quantity',
    orderId: 'Order ID',
    products: 'Products',
    cost: 'Item cost',
    toPay: 'To pay',
    showDetail: 'Show detail',
    hideDetail: 'Hide detail',
    catalog: 'Catalog',
    items: 'Items',
    itemsCost: 'Items cost',
    shipping: 'Shipping',
    vat: 'VAT',
    shippingCost: 'Shipping cost',
    recipient: 'Recipient',
    channel: 'Channel',
    product: 'Product',
    securedPayments: 'Payment are secured and encrypted',
    payOrder: 'Pay order',
    confirmOrder: 'Confirm order',
    privacypolicy: 'I’ve read and accept the [privacy]',
    privacy: 'terms and conditions',
    paymentDetail: 'Payment details',
    checkPrivacy: 'Please read and check privacy policy',
    failed: 'Your payment failed. Try again later.',
    payPal: 'Pay with PayPal',
    payPalDescription: 'You will be redirected to PayPal',
    creditCard: 'Pay with credit card',
    selectType: 'Please select the payment type you want',
    waitingForVerification: 'Your billings informations are in validation.',
    payingOrders: 'You are paying the following order(s)',
    payingOrder: 'You are paying the following order',
    quantitiesMissing:
      'The orders you are trying to pay have some out of stocks products.',
    warningShipToMe:
      'Lowest shipping fee automatically selected because you chose Ship To Me.',
    warningNoAddress:
      'Your order address is not complete, please update it to complete your order',
    warningNoCarrierAvailable:
      'Payment disabled because shipping is not available to your country',
    completed: {
      title: 'Thank you for your purchase!',
      subtitle: 'Thanks for placing order',
      merchantOrdersFromOneOrder:
        'Your order will be split in the following orders:',
      merchantOrdersFromMultipleOrders:
        'Your orders will be split in the following orders',
      spedition: 'Spedition',
      subtitleMultiple: 'Thanks for placing orders',
      text1: 'We will send you a notification within 5 days when it ships.',
      text2:
        'If you have any question or queries then fell to get in contact us.',
      CTA: 'Go to catalog',
      email:
        'Soon you will receive an email with the invoice of your order attached',
      orderRecap: 'Order recap',
      recapRelative: 'Recap relative to order',
      toPay: 'To be paid on delivery',
      emailCod: 'You will receive an email when order is validated',
      carrier: 'Shipping cost:',
      quantity: 'Quantity:',
      total: 'Total:',
      totalProdoct: 'Total product:',
      cashbackOrder: 'Cashback for this order',
      cashbackCTA: 'Take a look at your cashback amount',
      taxes: 'Taxes',
      shipping: 'Shipping',
      cashback: 'Cashback',
      share: 'Share',
      detail: 'Detail',
      seeProduct: 'Go to catalogue',
    },
    error: {
      title: 'Error on your purchase!',
      errorPayment: 'Something went wrong with payment',
      notification: 'You will receive a notification of error payment',
      CTA: 'Go to dashboard',
      secondCTA: 'Retry payment',
    },
    authorize: {
      title: 'Authorizing payment',
    },
  },
  payment_plan: {
    orderId: 'Order ID',
    products: 'Products',
    cost: 'Item cost',
    toPay: 'To pay',
    showDetail: 'Show detail',
    hideDetail: 'Hide detail',
    catalog: 'Catalog',
    items: 'Items',
    itemsCost: 'Items cost',
    shipping: 'Shipping',
    vat: 'VAT',
    shippingCost: 'Shipping cost',
    recipient: 'Recipient',
    channel: 'Channel',
    product: 'Product',
    securedPayments: 'Payment are secured and encrypted',
    payOrder: 'Pay order',
    privacypolicy: 'I’ve read and accept the [privacy]',
    privacy: 'terms and conditions',
    checkPrivacy: 'Please read and check privacy policy',
    waitingPayment:
      'It seems you have a pending payment. Please retry later or contact our technical support.',
    noCompanyEdit: 'Contact support to modify your billing information',
    paymentDetail: 'Payment details',
    billingAgreementDescription:
      'by activating this payment method, it will be easier to complete your future orders. Remember to read the terms below. Thanks',
    failed: 'Your payment failed. Try again later.',
    completed: {
      title: 'Thank you for your purchase!',
      text1:
        'If you have any question or queries then fell to get in contact us.',
      CTA: 'Go to products',
      createList: 'Create a list',
      bsocial: {
        CTA: 'Start Now',
        whatDoIDo: 'What do I do now?',
        text1: 'Click on the button below and fill in the form',
        text2: 'Browse your list to find a product you like',
        text3:
          'on Facebook, WhatsApp, Telegram and many others to start selling now. Repeat!',
        share: 'Share',
      },
      turnkey: {
        CTA: 'Start Now',
        whatDoIDo: 'What do I do now?',
        text1: 'Click on the button below and fill in the form',
        text2: 'Browse your list to find a product you like',
        text3:
          'on Facebook, WhatsApp, Telegram and many others to start selling now. Repeat!',
      },
    },
    error: {
      title: 'Error on your plan purchase!',
      errorPayment: 'Something went wrong with payment',
      notification: 'You will receive a notification of error payment',
      CTA: 'Go to dashboard',
      secondCTA: 'Retry payment',
    },
    authorize: {
      title: 'Authorizing payment',
    },
    turnkey: {
      title: 'Do you want a turnkey e-commerce too? We’ll take care of it!',
      CTA: 'Discover more',
    },
    yourRecap: 'Your purchase',
    subtitle: 'Do you already have a list?',
    email:
      'Soon you will receive an email with the invoice of your order attached',
    browseCatalog:
      'Browse the product catalog and choose which ones to add to your list, create your markup rules and select on which one channel start selling',
    payment: 'Payment:',
    plan: 'Plan:',
    expire: 'Scadenza:',
    service: 'Service:',
  },
  payment_support_gold: {
    orderId: 'Order ID',
    products: 'Products',
    cost: 'Item cost',
    toPay: 'To pay',
    showDetail: 'Show detail',
    hideDetail: 'Hide detail',
    catalog: 'Catalog',
    items: 'Items',
    itemsCost: 'Items cost',
    shipping: 'Shipping',
    vat: 'VAT',
    shippingCost: 'Shipping cost',
    recipient: 'Recipient',
    channel: 'Channel',
    product: 'Product',
    securedPayments: 'Payment are secured and encrypted',
    payOrder: 'Pay order',
    privacypolicy: 'I’ve read and accept [privacy]',
    privacy: 'privacy policy',
    checkPrivacy: 'Please read and check privacy policy',
    paymentDetail: 'Payment details',
    failed: 'Your payment failed. Try again later.',
    completed: {
      title: 'Thank you for your purchase!',
      text1:
        'If you have any question or queries then fell to get in contact us.',
      CTA: 'Next',
    },
    error: {
      title: 'Error on your plan purchase!',
      errorPayment: 'Something went wrong with payment',
      notification: 'You will receive a notification of error payment',
      CTA: 'Go to dashboard',
      secondCTA: 'Retry payment',
    },
    authorize: {
      title: 'Authorizing payment',
    },
  },
  profile: {
    submenu: {
      user: 'User',
      profile: 'Profile',
      referral: 'Referral',
      billing: 'Invoice',
      address: 'Address',
      ecredit: 'Ecredit',
      logout: 'Logout',
      backAdmin: 'Go back as admin',
      technicalSupport: 'Technical support',
      mode: 'Mode',
      selectLang: 'Language',
    },
    title: {
      title: 'Profile',
    },
    tab: {
      general: 'General',
      ecredit: 'Ecredit',
      shipping: 'Shipping',
      password: 'Password',
      affiliation: 'Affiliation',
      invoice: 'Invoice',
    },
    plan: {
      title: 'Your plan',
      empty: 'Start your business',
      expire: 'Expired date',
      noplan: 'no plan selected',
      btn: 'Choose your plan',
      cta: 'Upgrade your plan',
      unsubscribe: 'Cancel subscription',
      unsubscribeTitle: 'Are you sure you want to unsubscribe?',
      confirm: 'Confirm',
      cancel: 'Cancel',
      maxProducts: 'Product limit',
      whatIncludes: "Cos'è incluso",
    },
    user: {
      name: 'Name',
      mail: 'Email',
      role: 'Role',
      expire: 'Expired date',
      title: 'User info',
      empty: 'free',
    },
    list: {
      title: 'Your list',
      channel: 'Channel',
      empty: 'Create your first list!',
      cta: 'Create list',
      product: 'products',
      otherList: 'Create another list',
      noChannel: 'No channel',
    },
    password: {
      control: 'Password must be minimum 6+',
      equal: 'Password and confirm password does not match',
      success: 'Password changed successfully',
      old: 'Insert your old password',
      changePassword: 'Change password',
      newPassword: 'New password',
      insertPassword: 'Insert new password',
      subtitle: 'Please check your email and confirm new password',
    },
    address: {
      title: 'Billing address',
      shipping: 'Shipping address',
      btn: 'Edit',
      modalTitle: 'Confirm delete address',
      modalDescription: 'Are you sure you want to delete address?',
      confirm: 'Confirm',
      addressSaved: 'Address saved successfully',
      addressUpdated: 'Address updated successfully',
      addressDeleted: 'Address deleted successfully',
      validVat: 'Your vat is valid',
      invalidVat: 'Invalid vat',
    },
    invoice: {
      title: 'Invoice history',
      empty: 'No invoice found',
      date: 'Date order',
      cost: 'Cost',
      code: 'Code',
      plan: 'Plan',
      type: 'Type',
      Document: 'Document',
      noInvoice:
        'Here you can find and download your invoice.There are no invoice for the moment.',
      month: 'Month',
      year: 'Year',
    },
    ecredit: {
      wallet: 'YOUR WALLET',
      return: 'Return',
      transaction: 'Transaction',
      noEcredit:
        'Here you can find your ecredit. You dont have any order for the moment',
      amount: 'Amount',
      maxAmount: 'Max 100 Euro',
      iban: 'Iban',
      accountHolder: 'Account holder',
      CTA: 'Invia richiesta',
      withdrawTitle: 'Richiesta di addebito',
      withdrawError: 'Error!',
      withdrawSuccess: 'Success!',
      withdraw: 'Withdraw',
      returnTitle: 'Return',
      orderDate: 'Order date',
      orderId: 'Order number',
      actions: 'Document',
      type: 'Type',
      return_order: 'Return',
      cashback_products: 'Cashback',
      signup_credit: 'Signup credit',
      signup_credit_expired: 'Signup credit expired',
      payment: 'Payment',
      credit: 'Credit',
      referral: 'Promocode',
      credit_referral_products: 'Referral products',
      info: "You haven't yet reached the €100 threshold to request the transfer",
      total: 'TOTAL',
    },
    referral: {
      titleTab: 'YOUR FRIENDS',
      tabSubtitle:
        'Find friends who have purchased at least one product in the list below. Continue sharing with them and others to accumulate more and more cashback.',
      title: 'Share and earn',
      invite:
        'Share our products with a friend. You both earn! [10%] for him and [10%] for you for 12 months',
      affiliatedEarn:
        'He receives a [number] welcome for registering on BDroppy',
      copy: 'Copy link',
      copied: 'Copied',
      revenue: 'Revenue',
      products: 'Products',
      service: 'Services',
      active: 'Active',
      subscriptionDate: 'Subscription date',
      expired: 'Expired',
      invited: 'Invited',
      noUser: 'No user',
      daysLimit:
        'You have [number] days from your registration date to share the link',
      faq: {
        question_1: 'Who can join',
        answer_1:
          'BDroppy’s Affiliate Programme is aimed at anyone who wants to find an alternative, easy-to-set-up, flexible and free source of income: online marketing enthusiasts, entrepreneurs, influencers, youtubers, content creators, freelancers',
        question_2: 'How to collect your commissions',
        answer_2:
          'On BDroppy, you can access your dedicated section at any time, where you can see the total of your commissions. You can decide whether to use them to make your purchases on BDroppy or to request a transfer to have your earnings directly into your bank account.',
        question_3: 'How do I monitor my referrals and commissions?',
        answer_3:
          'On BDroppy, you will have a special section where you can monitor your commissions, the people you have sent your link to, activated registrations and expired registrations.',
      },
    },
  },
  channel: {
    list: {
      createList: 'Create list',
      products: 'Products',
      addProducts: 'Add products',
      edit: 'Edit',
      currency: 'Currency',
      dropshippingShipments: 'Shipments',
      markup: 'Markup',
      language: 'Language',
      status: 'Status',
      shipToMe: 'Ship to me',
      shipToCustomer: 'Ship to customer',
      empty: 'Your import list is empty, create your first list',
      addToWishlist: 'Add product to wishlist',
      removeFromWishlist: 'Remove product from wishlist',
    },
    new: {
      comingSoon: 'COMING SOON',
      turnkey: 'Turnkey webstore',
      saveAndClose: 'Save and close',
      selectChannel: 'Choose channel',
      activateChannel: 'Activate channel',
      listName: 'List name',
      currency: 'Currency',
      language: 'Language',
      choose: 'Choose your channel',
      needPlan: 'You need to buy a plan in order to activate a channel',
      selectProduct: 'Select product',
      activateChannelInfo:
        "You can't activate any channel because you are free user. In order to activate channel/s you need to buy a plan",
      stepSelectChannel: '2 - Select Channel',
      stepManageSetting: '1 - Manage your list',
      activatePlan: 'Activate a plan',
      cantActivate: "You can't activate any channel, first need to buy a plan",
      cantActivateTurnkey:
        "You can't activate turnkey, you need a plan that allows activation of turnkey webstore (Easy, Professional or Enterprise)",
    },
    edit: {
      productList: 'Product list',
      downloadFile: 'Download file',
      modalTitle: 'Download catalog file',
      modalSubTitle: 'Select language and format of the file',
      modalCta: 'Download',
      modalCta2: 'Share it',
      settings: 'Settings',
      changeLang: 'Select language of product description',
      selectLanguages: 'Select languages',
      selectFormat: 'Select format',
      channelName: 'Channel',
      activateChannel: 'Activate channel',
      emptyList: 'Your list is empty',
      emptyListSubLabel: 'Add products and start your business',
      emptyListDeleted: 'Your list is deleted',
      emptyListDeletedSub: 'you need to buy a plan in order to...',
      noProductFound: 'Ops no product found',
      addProducts: 'Add products',
      deleteProducts: 'Delete products',
      removeProduct: 'Delete',
      editProduct: 'Edit',
      productSelected: ' products',
      selected: 'Selected',
      selectProduct: 'Select product',
      removeProducts: 'Remove product',
      pageSelectedProduct: 'All [products] on this page are selected',
      selectAllProduct: 'Select all the products on the catalog',
      selectAllProductMobile: 'Add all products',
      linkCopied: 'Link copied',
      disableAutoImport:
        'To make changes on your list you need to deactivate automatic import setting.',
      here: 'HERE.',
      channel: {
        channelConnected: 'Channel connected',
        channelNotConnected:
          'Connection error. Your Amazon Seller account is not actually active or a valid payment method is not connected.\n' +
          'Please contact Amazon to receive further info.',
        alertEbay:
          'Attention: it takes up to 4 hours to start viewing your products online',
        title: 'Turnkey site',
        deleteChannel: 'Delete channel',
        shopifyTemplate: 'Shopify platform has only one selectable template',
        disconnectChannel: 'Disconnect channel',
        save: 'Save',
        upgradePlan: 'Upgrade plan',
        close: 'Close',
        confirm: 'Confirm',
        buyNow: 'Buy now',
        registerTitle: 'Do you want to register a new domain?',
        registerSubtitle: '',
        selectDataTitle: 'Please select currency and language ',
        selectDataTitleWoocommerce:
          'You can select one language and currency for your website',
        selectDataTitlePrestashop:
          'You can select three languages and three currencies for your website',
        selectPlatform: 'Please select platform',
        selectPlatformPlaceholder: 'Choose platform',
        selectPlatformLabel: 'Select platform',
        selectPlatformTitleWoocommerce:
          'Choose the platform you prefer for your website',
        radio1: 'Yes, thanks',
        radio2: 'No, I already have a domain',
        domainTitle: 'Insert your domain',
        domainPlaceholder: 'domain.com',
        domainDescriptionYes1:
          'Our offer includes a free .com or .business domain.',
        domainDescriptionYes2:
          'If you want to register another extension or you already own a\n' +
          '              domain, select &apos;No, i already have a domain&apos;.',
        domainDescriptionNo1:
          'To use this domain you need to be able to access its control panel\n' +
          '              to change the DNS zone.',
        domainDescriptionNo2:
          'After completing the form you will receive an email with all the\n' +
          '              information to direct your domain to our servers.',
        siteLanguage: 'Site language',
        siteLanguagePlaceholder: 'Select language',
        siteCurrency: 'Site currency',
        siteCurrencyPlaceholder: 'Site currency',
        template: 'Choose template',
        templateButton: 'Select template',
        preview: 'Preview',
        saveSettings: 'Save settings',
        connectChannel: 'Connect channel',
        connectToEbay: 'Connect to Ebay',
        connectToAmazon: 'Connect to Amazon',
        changeChannel: 'Change channel',
        goBack: 'Go back',
        search: 'Search',
        chooseTemplate: 'Choose template',
        chooseTemplatePrestashop: 'Choose one Prestashop template',
        chooseTemplateWoocommerce: 'Choose one Woocommerce template',
        chooseTemplateEasy: 'Choose basic template from Woocommerce or Shopify',
        noDomain: 'Fill in the Domain field first',
        domainAvailable: 'Available',
        domainUnavailable: 'Unavailable',
        domainSelected: 'Domain selected',
        selected: 'Selected',
        howToConnectAmazon: 'How to connect your Amazon account to BDroppy',
        howToConnectEbay: 'How to connect you Ebay account to BDroppy',
        selectCountryAmazon:
          'Select the country of your market, go to Amazon Seller Central and authorize BDroppy as a new developer',
        selectCountryEbay:
          'Select the country of your market and insert you Ebay username',
        amazonSeller:
          'Enter your Seller ID and your Mws Auth Token you receive from Amazon',
        ebaySeller:
          'Allow the sharing of your data with TARS, create shipping, return and payment policy on Ebay',
        paymentPolicy: 'Payment policy',
        fullfillmentPolicy: 'Fullfillment policy',
        returnPolicy: 'Return policy',
        countryEbay: 'Ebay market country',
        countryAmazon: 'Amazon market country',
        selectCountry: 'Select market country',
        ebayUsername: 'Ebay Username',
        myEbayUsername: 'My Ebay Username',
        amazonSellerID: 'Seller ID',
        authToken: 'Mws Auth Token',
        automaticUpdate:
          'Disable this option if you want to deactivate automatic update',
        automaticPrice: 'Automatic update price',
        statusChannel: 'Status channel',
        logs: 'See logs',
        connection: 'Check connection to channel',
        connect1: 'How to connect your store to BDroppy',
        prestaShopConnect2: 'Install the BDroppy module from',
        woocommerceConnect2: 'Install the BDroppy plugin from',
        prestaShopConnect3: 'Activate the module in your module page',
        woocommerceConnect3: 'Activate the plugin in your plugins page',
        prestaShopConnect4: 'Go to Prestashop > Dashboard > BDroppy',
        woocommerceConnect4: 'Go to WordPress > Dashboard > BDroppy',
        downloadLatestVersion: 'You can donwload the latest version of plugin',
        connect5: 'Login from plugin with your email and BDroppy password',
        connect6: 'Having troubles?',
        contattaci: 'Contact us here',
        qui: 'here',
        webUrl: 'Your website URL',
        ssDescription:
          'The integration does not support changes made on Squarespace. For all product changes such as price updates, inventory and channel connection, go to your dashboard on Bdropppy',
        imageOrientation: 'Product Image Orientation',
        selectImageOrientation: 'Select image orientation',
        selectImageOrientationSub:
          'Select the orientation of the image in the website.',
        storeName: 'Store name:',
        dashboardLink: 'Shopify dashboard link:',
        ADMIN_DASHBOARD: 'Dashboard',
        storeNameTitle: 'Store name',
        payPalTitle: 'Paypal email',
        ecreditInfo:
          'You have selected to receive your money as a credit into your purse. You can request the transfer of your earnings to your bank upon reaching 100€.',
        payPalDescription:
          'Insert your email of paypal account in order to activate paypal payment',
        confirmationEmail: 'Confirmation Email',
        paypalEmail: 'PayPal email',
        socialStoreName: 'Store name',
        storeNameDescription:
          'Il nome del negozio apparirà nel link di atterraggio dei tuoi prodotti store.mybsocial.com',
        products: 'PRODUCTS',
        orders: 'ORDERS',
        statusConnection: 'Connection status:',
        lastUpdated: 'Last product updated',
        lastImported: 'Last product imported',
        productErrors: 'Products with errors',
        connected: 'Connected',
        disconnected: 'Disconnected',
        statusNotOk: 'Please fix connection using following',
        errorWix:
          'Warning, be sure that your application has Wix Store application installed',
        errorSquarespace:
          'Warning, Store pages are required for creating products in Squarespace and must be created by the merchant.',
        notPay:
          'You need to buy a plan in order to activate channel and update product on your website',
        modalTitle: 'Are you sure you want to disconnect channel?',
        modalSubtitle: 'Products removed on catalog message',
        congratulation: 'Congratulations',
        isConnected: 'is now connected!',
        helpPdf: 'Help PDF',
        titleHelpPdf: 'Here you can find a tutorial to connect channel',
        ebayToken:
          'Ebay disconnected, please check the username and then connect again',
        amazonToken: 'Amazon disconnected, please login again to Amazon',
        error: 'Error',
        integrationError:
          'Integration still being deactivated. Please wait 15 minutes before trying again',
        selectedDomain: 'Domain',
        selectedLanguage: 'Language',
        selectedCurrency: 'Currency',
        selectedTemplate: 'Template',
        selectedPlatform: 'Platform',
        selectTemplate: 'Please select a template',
        selectLanguage: 'Please select language and currency',
        selectLanguageAndCurrency:
          'Here you can customize language and currency of your list',
        descriptionSquarespace:
          'Thanks to BDroppy, you can have your Squarespace site configured with our fashion catalog integrated. Through the plugin, all data (products, prices and orders) will be automatically synchronized.',
        descriptionEbay:
          'With BDroppy you can sell our fashion catalog online on eBay and make money thanks to our dropshipping service: you will buy our products at a wholesale price and resell them at the best price to your customers',
        descriptionPrestashop:
          'Thanks to BDroppy, you can have your Prestashop site configured with our fashion catalog integrated. Through the plugin, all data (products, prices and orders) will be automatically synchronized.',
        descriptionAmazon:
          'With BDroppy you can integrate our fashion catalog into your Amazon store, with the dropshipping service included, to resell thousands of original designer fashion products online',
        descriptionTurnkey:
          'We create your online store on Woocommerce or Prestashop, with a pre-integrated fashion catalog. You’ll have your e-commerce platform ready to start selling and earning money right away!',
        descriptionWix:
          'Thanks to BDroppy, you can have your Wix site configured with our fashion catalog integrated. Through the plugin, all data (products, prices and orders) will be automatically synchronized.',
        descriptionWoocommerce:
          'Thanks to BDroppy, you can have your Woocommerce site configured with our fashion catalog integrated. Through the plugin, all data (products, prices and orders) will be automatically synchronized.',
        descriptionEcwid: 'Ecwid placeholder description',
        descriptionSocialEcommerce: 'Social Ecommerce placeholder description',
        faq: 'Faq',
        wixDescription:
          'Dear customer, to activate the channel you will be redirected to the wizard',
        faqSubtitle: 'Most common questions',
        needHelp: 'Do you need help?',
        needHelpSubtitle: 'do you need help subtitle',
        contactUs: 'Contact us',
        marketplaceTitle: 'Ecommerce e Marketplace Integrati',
        marketplaceDescription:
          'BDroppy si integra con le migliori piattaforme di e-commerce e marketplace per far crescere il tuo business in dropshipping.',
        bSocialDescription:
          'Vendi sui tuoi social network preferiti, in pochi click e senza bisogno di un’e-commerce. Condividi con la tua community, vendi, guadagna',
        turnkeyDescription:
          'Affidati al nostro team di esperti per una scelta professionale: creeremo per te un e-commerce personalizzato, integrato con il nostro servizio dropshipping.',
        turnkeyTitle: 'Sito Chiavi In Mano',
        ebay: {
          faq: {
            title: 'Most common questions',
            question_1:
              'Do I have to sell the same products in-store as I do online?',
            answer_1:
              'No, you can choose exactly what products to sell online, in-store, or both.',
            question_2:
              'Do I have to sell the same products in-store as I do online?',
            answer_2:
              'No, you can choose exactly what products to sell online, in-store, or both.',
            question_3:
              'Do I have to sell the same products in-store as I do online?',
            answer_3:
              'No, you can choose exactly what products to sell online, in-store, or both.',
            question_4:
              'Do I have to sell the same products in-store as I do online?',
            answer_4:
              'No, you can choose exactly what products to sell online, in-store, or both.',
          },
        },
        wix: {
          startSelling: 'Start selling',
          startSellingDescription:
            'Sell your product directly from your website',
          activateChannel: 'Connect channel',
          activateDescription: 'You will be redirect on Wix',
          waitConnection: 'Wait a few minutes',
          waitConnectionDescription: 'wait the sync of product',
          review: {
            title: 'Condividi la tua esperienza',
            subTitle:
              'Aiutaci a migliorare BDroppy - Dropshipping. Ti invitiamo a condividere la tua opinione su di noi.',
            cta: 'Click here to leave a review',
          },
          faq: {
            title: 'Most common questions',
            question_1:
              'Do I have to sell the same products in-store as I do online?',
            answer_1:
              'No, you can choose exactly what products to sell online, in-store, or both.',
            question_2:
              'Do I have to sell the same products in-store as I do online?',
            answer_2:
              'No, you can choose exactly what products to sell online, in-store, or both.',
            question_3:
              'Do I have to sell the same products in-store as I do online?',
            answer_3:
              'No, you can choose exactly what products to sell online, in-store, or both.',
            question_4:
              'Do I have to sell the same products in-store as I do online?',
            answer_4:
              'No, you can choose exactly what products to sell online, in-store, or both.',
          },
        },
        amazon: {
          faq: {
            title: 'Most common questions',
            question_1:
              'Do I have to sell the same products in-store as I do online?',
            answer_1:
              'No, you can choose exactly what products to sell online, in-store, or both.',
            question_2:
              'Do I have to sell the same products in-store as I do online?',
            answer_2:
              'No, you can choose exactly what products to sell online, in-store, or both.',
            question_3:
              'Do I have to sell the same products in-store as I do online?',
            answer_3:
              'No, you can choose exactly what products to sell online, in-store, or both.',
            question_4:
              'Do I have to sell the same products in-store as I do online?',
            answer_4:
              'No, you can choose exactly what products to sell online, in-store, or both.',
          },
        },
        turnkey: {
          faq: {
            title: 'Most common questions',
            question_1:
              'Do I have to sell the same products in-store as I do online?',
            answer_1:
              'No, you can choose exactly what products to sell online, in-store, or both.',
            question_2:
              'Do I have to sell the same products in-store as I do online?',
            answer_2:
              'No, you can choose exactly what products to sell online, in-store, or both.',
            question_3:
              'Do I have to sell the same products in-store as I do online?',
            answer_3:
              'No, you can choose exactly what products to sell online, in-store, or both.',
            question_4:
              'Do I have to sell the same products in-store as I do online?',
            answer_4:
              'No, you can choose exactly what products to sell online, in-store, or both.',
          },
        },
        return: {
          faq: {
            title: 'Most common questions',
            question_1:
              'Do I have to sell the same products in-store as I do online?',
            answer_1:
              'No, you can choose exactly what products to sell online, in-store, or both.',
            question_2:
              'Do I have to sell the same products in-store as I do online?',
            answer_2:
              'No, you can choose exactly what products to sell online, in-store, or both.',
            question_3:
              'Do I have to sell the same products in-store as I do online?',
            answer_3:
              'No, you can choose exactly what products to sell online, in-store, or both.',
            question_4:
              'Do I have to sell the same products in-store as I do online?',
            answer_4:
              'No, you can choose exactly what products to sell online, in-store, or both.',
          },
        },
        ecwid: {
          startSelling: 'Start selling',
          startSellingDescription:
            'Sell your product directly from your website',
          activateChannel: 'Connect channel',
          activateDescription: 'You will be redirect on Ecwid',
          waitConnection: 'Wait a few minutes',
          waitConnectionDescription: 'wait the sync of product',
        },
        ekm: {
          startSelling: 'Start selling',
          startSellingDescription:
            'Sell your products directly from your website',
          activateChannel: 'Connect channel',
          activateDescription: 'You will be redirect on Ekm',
          waitConnection: 'Wait a few minutes',
          waitConnectionDescription: 'wait the sync of product',
        },
        woocommerce: {
          startSelling: 'Start selling',
          startSellingDescription:
            'Sell your products directly from your website',
          activateChannel: 'Connect channel',
          activateDescription: 'You will be redirect on Woocommerce',
          waitConnection: 'Wait a few minutes',
          waitConnectionDescription: 'wait the sync of product',
          shopUrl: 'Shop url',
          importAsPublished: 'Import as published',
          shopDescription: 'Shop url ',
        },
        shopify: {
          startSelling: 'Start selling',
          startSellingDescription:
            'Sell your products directly from your website',
          activateChannel: 'Connect channel',
          activateDescription: 'You will be redirect on Shopify',
          waitConnection: 'Wait a few minutes',
          waitConnectionDescription: 'wait the sync of product',
          waitConnection24h:
            'The app will be installed within the next 48 working hours',
          shopUrl: 'Shop URL',
          shopDescription: 'Shop url ',
          help: 'help',
          contactUs: 'Contact us',
          alert:
            'Please insert just the name of shop without https:// or myshopify.com',
          active: 'Your Shopify store is connected',
          admin:
            "Per cambiare messaggio visualizzato dall'utente metter su Active lo switch. Dopo aver cambiato clicca sul pulsante Salva. Per eliminare il canale clicca sull'icona del cestino e clicca conferma nel modal.",
          submit: 'Submit',
          save: 'Save',
          excludeFromUpdate: 'Exclude from update',
          excludeFromUpdateSubtitle:
            'Select what you want to exclude from your update. ',
          price: 'Price',
          description: 'Description',
          importAsPublishedTitle: 'Import product as published',
          importAsPublishedTitleSubtitle:
            'Import product directly as published instead of draft',
        },
        squarespace: {
          selectImageOrientationTitle: 'Image orientation',
          selectImageOrientation:
            'Select the orientation of the image in the website.',
          startSelling: 'Start selling',
          startSellingDescription:
            'Sell your product directly from your website',
          activateChannel: 'Connect channel',
          activateDescription: 'You will be redirect on Squarespace',
          waitConnection: 'Wait a few minutes',
          waitConnectionDescription: 'wait the sync of product',
          faq: {
            title: 'Most common questions',
            question_1:
              'Do I have to sell the same products in-store as I do online?',
            answer_1:
              'No, you can choose exactly what products to sell online, in-store, or both.',
            question_2:
              'Do I have to sell the same products in-store as I do online?',
            answer_2:
              'No, you can choose exactly what products to sell online, in-store, or both.',
            question_3:
              'Do I have to sell the same products in-store as I do online?',
            answer_3:
              'No, you can choose exactly what products to sell online, in-store, or both.',
            question_4:
              'Do I have to sell the same products in-store as I do online?',
            answer_4:
              'No, you can choose exactly what products to sell online, in-store, or both.',
          },
        },
      },
    },
    settings: {
      general: {
        title: 'Settings',
        listName: 'List name',
        currency: 'Currency',
        language: 'Language',
        generalSetting: 'General setting',
        generalSettingSubtitle:
          'Manage settings of your list name, currency and language ',
        save: 'Save',
        deleteTitle: 'Delete rules',
        deleteSubtitle: 'Are you sure you want to delete rules?',
        deleteSubtitleConfirm:
          'If you click on confirm all the rules will be deleted, are you sure you want to delete?',
        deleteAll:
          'If you decide to update the catalog automatically the query rules will be deleted',
        cancel: 'Cancel',
        confirm: 'Confirm',
        saveSuccess: 'You have updated your list successfully.',
        ruleName: 'Rule name',
        minQuantity: 'Min quantity',
        confirmSavedChanges: 'Changes that you made may not be saved.',
      },
      markup: {
        infoCatalogName:
          'Give a name to your product list and create your own catalog.',
        infoMarkUp: 'Choose on which base price to apply your markup settings.',
        infoRounding: 'Choose how to round off your selling prices.',
        markupSettings: 'Markup settings',
        socialEcommerce:
          'Markup settings are disabled for social ecommerce users',
        markupSettingsInfo:
          'Customize your profit setting. You can add different markups and apply them to different product categories.',
        baseCost: 'Base cost',
        rounding: 'Rounding',
        markupPer: 'Markup%',
        userTaxPer: 'User tax %',
        addMoreMarkupRules: 'Add more markup rules',
        name: 'Name',
        rules: 'Rules',
        newRule: 'New rule',
        applyTo: 'Apply to',
        applyToInfo:
          'To whole catalog will follow the main markup rule unless you pick specific categories to apply the markup',
        selectFilter: 'Select filter',
        language: 'Language',
        pricings: {
          0: 'None',
          streetPrice: 'Street price',
          bestTaxable: 'Cost (no VAT)',
          suggestedPrice: 'Suggested price',
        },
        roundings: {
          no: 'No rounding (es. 23.65)',
          xxx_00: 'Round to xxx.00 (es. 24.00)',
          xxx_90: 'Round to xxx.90 (es. 23.90)',
          xxx_99: 'Round to xxx.99 (es. 23.99)',
          xx9_00: 'Round to xx9.00 (es. 29.00)',
          xx9_99: 'Round to xx9.99 (es. 29.99)',
          xx900: 'Round to xx900 (es. 2900)',
          xx990: 'Round to xx990 (es. 2990)',
          xx000: 'Round to xx000 (es. 2000)',
          xx9000: 'Round to xx9000 (es. 29000)',
          xx9900: 'Round to xx9900 (es. 29900)',
          xx0000: 'Round to xx0000 (es. 20000)',
        },
        cancel: 'Cancel',
        save: 'Save',
      },
      query: {
        addMoreQueryRules: 'Add more query rules',
      },
      upload: {
        automaticArrivals: 'Automatic arrivals uploads',
        manualSelection: 'Manual selection',
        allNewProducts: 'All new products published',
        automaticArrivalsInfo1:
          'Select the default settings to upload new arrivals.',
        automaticArrivalsInfo2:
          'Enable this option if you want the goods shipped to you and not to your end customer.',
        shipToMe: 'Ship to me',
        noShippingAddress: "Looks like you don't have a shipping address.",
        addShippingAddress: 'Add a shipping address',
        shipToCustomer: 'Ship to customer',
        newArrivalsUpload: 'New arrivals uploads',
        advancedSettings: 'Advanced settings',
        shippingSettings: 'Shipping',
        addFilters: 'Set rules with filters',
        useMinQuantity: 'Set min quantity',
        useMinQuantityInfo:
          'Products with quantities below this threshold will be hidden from your list.',
        importRules: 'Set import rules',
        importRulesSubtitle:
          'Enable this option to import products using advanced filters.',
        info: 'info test for upload',
        minQuantityDescription:
          "Select this option if you don't want to import products with low quantities",
        limitProduct:
          'Automatic upload is not available because your plan has limit number of products',
      },
      applyTo: {
        list: {
          category: 'Category',
          subcategory: 'Sub category',
          brand: 'Brand',
          gender: 'Gender',
          season: 'Season',
          bestTaxable: 'Cost',
          streetPrice: 'Street price',
          suggestedPrice: 'Suggested price',
        },
        genders: {
          kids: 'Kids',
          boy: 'Boy',
          girl: 'Girl',
          women: 'Women',
          unisex: 'Unisex',
          men: 'Men',
        },
        seasons: {
          fw: 'Fall / Winter',
          ss: 'Spring / Summer',
          'all-year': 'All year',
        },
        bestTaxables: {
          '=': 'Equal',
          '<>': 'Not equal',
          '>': 'Greater than',
          '>=': 'Greater than equal',
          '<': 'Less than',
          '<=': 'Less than equal',
          value: 'Value',
        },
      },
    },
  },
  products: {
    list: {
      confirm: 'Confirm',
      shareCTA: 'Share',
      share: "Look what I've just picked up for you!",
      shareStore: 'Look at my store!',
      shareSubtitle: 'Check this out on BDroppy!',
      titleFree:
        'Attiva subito un piano e inizia a vendere migliaia di prodotti moda firamta in modo automatico',
      subTitleFree:
        'Psss! Sai che puoi effettuare fino a 5 ordini manuali al mese senza costi di servizio? ',
      titleFreeCta: 'Choose a plan',
      titleFree2:
        'Oppure puoi effettuare fino a 5 ordini al mese e provare tutta la qualità dei nostri prodotti e del nostro servizio logistico distributivo, senza costi aggiuntivi',
      titleFreeCta2: 'Seleziona i prodotti',
      highlitedBrand: 'In evidenza',
      about1: 'Leader in fashion E-COMMERCE for 12 years',
      about2: 'ORIGINAL FASHION BRANDS',
      about3: 'SHIPMENTS PER YEAR',
      cartDescription:
        'Up to 5 manual orders per month included, purchases at wholesale prices, no service costs!',
      titleModal: 'Product details',
      titleModalError: 'Error detail',
      titleModalCountry: 'Select country for shipping',
      titleModalCurrency: 'Select currency',
      selectProduct: 'Select product',
      selectCountry: 'Select country',
      popular: 'Popular',
      removeProduct: 'Remove product',
      productSelected: 'selected',
      pageSelectedProduct: 'All [products] on this page are selected',
      selectAllProduct: 'Select all the products on the catalog',
      limitOrder: 'You can select max 10 products',
      finishOrder: 'Add product to cart and finish the order',
      searchProduct: 'Search by product',
      addToList: 'Add to list',
      addToCart: 'Add to cart',
      modalTitle: 'Add to your list',
      modalPlaceholder: 'Select your catalog',
      product: 'product',
      catalog: 'added to your catalog:',
      firstList: 'Create your first list',
      anotherList: 'Create your list',
      delete: 'Delete',
      profit: 'Profit',
      changeLang: 'Select language of product description',
      changeCountry: 'Select shipping country',
      changeCurrency: 'Select currency',
      currency: 'Currency',
      country: 'Country',
      language: 'Language',
      cost: 'Cost',
      sellPrice: 'Sell price',
      viewDetail: 'View detail',
      availability: 'Availability',
      availabilityLessThan5: 'Ultimi pezzi rimasti',
      outOfStock: 'Out of stock',
      rule: 'Rule',
      size: 'Size',
      retail: 'Retail',
      cashback: 'Cashback',
      suggestedPrice: 'Suggested price',
      suggested: 'Suggested',
      shippingCost: 'Shipping cost',
      shippingCostFrom: 'from',
      shippingFees: 'See all shipping fees',
      shippingFeesDelay: 'Delivery time can be longer than expected',
      selectLanguage: 'Select description language',
      selectLanguageAndCurrency:
        'Here you can customize language and currency of your list',
      limit100products:
        'You have reached the limit of 100 products on your catalog',
      limit1000products:
        'You have reached the limit of 1000 products on your catalog',
      queryRulesActive:
        'You have query rules activated on your catalog setting, deactivate it to add products manually',
      automaticUpdate:
        'You have automatic update on your list, deactivate it to add products manually',
      modelId: 'Model Id',
      productAddedStore: 'Product added to store',
      productRemovedStore: 'Product removed from store',
      productMaxLimit: 'Limit of products reached',
      quantity: 'Quantity',
      message: 'Message',
      extradiscount: 'Extra discount',
      sellAt: 'Sell at',
      esclIVA: 'escl IVA',
      header: {
        title_1: 'Authentic products only',
        title_2: 'Wholesale prices',
        title_3: 'Shipping from Italy',
      },
      filter: {
        new: 'New arrivals',
        old: 'Older arrivals',
        brandsAZ: 'Brands A-Z',
        brandsZA: 'Brands Z-A',
        title: 'sort',
      },
      tag: {
        shoes: 'Shoes',
        bags: 'Bags',
        watch: 'Watch',
        jeans: 'Jeans',
        sunglasses: 'Sunglasses',
      },
      billingAddressIncomplete: {
        title: 'Billing address is incomplete',
        description:
          'Your billing address is incomplete, please fill all the fields in your profile.',
        goTo: 'Go to edit',
      },
    },
    edit: {
      editProduct: 'Edit product',
      tag: 'Tag',
      save: 'Save',
      disableOptions:
        'Disable this option if you want to unpublish product on your channel',
      publishProduct: 'Publish product',
      vatIncluded: 'VAT included',
      description: 'Description',
      upload: 'Upload image',
      addImage: 'Add image',
      productImage: 'Product images',
      noTags: 'No tags available for this product',
    },
    detail: {
      btn: 'Add to list',
      sample: 'Order sample',
      text: 'Shipping costs and VAT not included',
      description: 'Description',
      availability: 'Availability',
      similarProducts: 'Similar products',
      sameBrand: 'Same Brand',
      placeholder: 'Select Size',
      profit: 'Profit',
      cost: 'Cost',
      retail: 'Retail',
      selectAndAdd: 'Select and add to cart',
      warningMessage: "You can't add product, limit of 10 products on cart",
      viewAll: 'View all',
      shipping: 'Shipping in 48-72 hours',
      return: 'Free returns',
      cashbackEarn: 'Hai gia guadagnato',
      sizesTable: 'Sizes table',
      cashback: 'Puoi accumulare ancora [withoutPlan]',
      withoutPlan: 'senza piano social',
      youCanEarn: 'Con questo prodotto puoi accumulare [ofCashback]',
      ofCashback: 'di cashback',
      limitReached: "You've reached the max cash amount of [maxcashback]",
      maxcashback: 'activate the plan social to earn more cashback',
      discoverPlan: 'discover plan',
      noProducts: 'No sizes found for this product',
      showSizes: 'Show size',
    },
  },
  store: {
    titleBox:
      'Per facilitare la tua esperienza di vendita online con BDroppy abbiamo selezionato queste estensioni per il tuo e-commerce.',
    subTitleBox:
      'Scopri i dettagli e scegli quelle che possono essere più utili per le tue vendite e per la gestione del tuo ecommerce. Il nostro team si occuperà dell’installazione e della configurazione.',
    subTitleBox2:
      'Our team will take care of the installation and configuration.',
    ctaBox: 'Inizia ora!',
    title: 'Service description',
    howToDo: 'How to do',
    addToCart: 'Add to cart',
    viewDetail: 'View detail',
    goToWebsite: 'Go to website',
    wcGoogleAnalytics:
      'Conosci i tuoi clienti e aumenta le vendite con la piattaforma di analisi dati n°1 al mondo.',
    wcFacebook:
      'Sfrutta tutte le potenzialità di Facebook per far crescere la tua attività online.',
    googleAds:
      'Raggiungi milioni di clienti coinvolti su Google con schede di prodotto e annunci sponsorizzati.',
    mailchimp:
      'Trasforma le mail in entrate. Coinvolgi i tuoi utenti con campagne di marketing multicanale.',
    tidio:
      'Coinvolgi i visitatori con una potente piattaforma multifunzione per il servizio clienti.',
    hurryTimer:
      'Crea urgenza e scarsità che guidano i clic dei tuoi clienti e aumentano le vendite.',
    unaTantum: 'Una tantum',
    yearly: 'Yearly',
    monthly: 'Monthly',
    uninstallService:
      'Remember that if you uninstall the plugin on your website you will need to rebuy the service',
    upsell: 'Increase the average value of your e-commerce customers',
    rules:
      'The most popular discount plugin with tons of easy-to-use interface features built to increase sales conversions.',
  },
  services: {
    bsocial: {
      pageTitle: 'BSocial',
      title: "Find out why BDroppy's BSocial is just what you need",
      feature_1: 'It is very easy to use, it just takes one click',
      feature_2:
        'Choose from over 5,000 products and share your favorite one on social media',
      feature_3: 'Start selling to your followers right away',
      feature_4: 'You just need a smartphone',
      cta: 'Start here',
      how_1: '1. Create your own product list',
      how_2: '2. Share one product at a time',
      how_3: '3. Discover the products',
      how_4: '4. Sell ​​immediately',
      how_1_p: 'Choosing from over 5,000 designer fashion items',
      how_2_p: 'With your network of friends, family and followers',
      how_3_p: 'Your community loves most',
      how_4_p: 'With a click!',
    },
    dropshipping: {
      pageTitle: 'Dropshipping',
      title: 'A multi-channel management platform',
      feature_1: 'A catalogue with over 100 top fashion brands',
      feature_2: '100% original branded fashion products',
      feature_3: 'Catalog export in xls, csv, xml, json formats',
      feature_4: 'Fast and reliable shipping from Italy',
      feature_5: 'Multilingual and multicurrency management',
      cta: 'Start here',
      how_1: '1. Create your own product list',
      how_2: '2. Share one product at a time',
      how_3: '3. Discover the products',
      how_4: '4. Sell ​​immediately',
      how_1_p: 'Choosing from over 5,000 designer fashion items',
      how_2_p: 'With your network of friends, family and followers',
      how_3_p: 'Your community loves most',
      how_4_p: 'With a click!',
    },
    turnkey: {
      pageTitle: 'Turnkey',
      title:
        'Would you like a professional turnkey fashion e-commerce and all the BDroppy dropshipping technology?',
      subTitle:
        'We create your online store with a pre-integrated fashion catalog. You’ll have your e-commerce platform ready to start selling right away!',
      feature_1:
        'Customise your site by choosing among 20 languages and 24 currencies!',
      feature_2: 'Domain and hosting included',
      feature_3: 'Mobile friendly Web design',
      feature_4: 'Social oriented',
      feature_5: 'Integrated payment system',
      cta: 'Start here',
      how_1: 'Reach new markets and expand your target;',
      how_2: "Don't be bound by opening hours: your shop will be open 24H / 7",
      how_3: 'Zero inventory costs and risks',
      how_4: 'Take advantage of fast and reliable shipments from Italy',
      how_5: 'Possibility of offering personalized discounts and promotions',
      how: 'Thanks to your own TURNKEY e-commerce you can:',
    },
  },
  pages: {
    dashboard: 'Dashboard',
    productList: 'My import lists',
    createList: 'Create list',
    findProduct: 'Product catalog',
    profile: 'Profile',
    order: 'Order list',
    return: 'Return list',
    newOrder: 'Create new order',
    detail: 'Product detail',
    pricing: 'Pricing',
    home: 'Home',
    admin: 'Admin',
    plansTranslation: 'Plans translations',
    usersManagement: 'Users management',
    returnsManagement: 'Returns management',
    photoDownload: 'Photo download',
    shipping: 'Shipping fees',
    technicalSupport: 'Technical support',
    billsAndCustomers: 'Bills and Customers',
  },
  sidebar: {
    home: 'Home',
    yourOrders: 'Your orders',
    hello: 'hello,',
    appStore: 'Apps',
    productCatalog: 'Shop by category',
    allCategory: 'All catalog',
    resellerArea: 'Area business',
    createList: 'Create your first list',
    resources: 'Resources',
    myList: 'Import lists',
    salesChannel: 'Sales channel',
    myStore: 'Store',
    orders: 'Orders',
    orderList: 'Order list',
    return: 'Return list',
    photo: 'Photo download',
    faq: 'Faq and tutorials',
    technical: 'Technical support',
    pricing: 'Pricing',
    affiliation: 'Affiliation',
    promo: 'Promo',
    admin: 'Admin',
    plansTranslations: 'Plans features',
    labelTranslations: 'Translations',
    usersManagement: 'Users management',
    returnsManagement: 'Returns management',
    invoicesManagement: 'Invoices management',
    upgrade: 'Upgrade plan',
    startBusiness: 'Start your business',
    shipping: 'Shipping fees',
    billsAndCustomers: 'Bills and Customers',
    createNewList: 'Create new list',
    privacy: 'Privacy Policy',
    blog: 'Blog',
    terms: 'Terms and conditions',
    collapse: 'Collapse',
    management: 'Management',
    services: 'Services',
    turnkey: 'Turnkey Webstore',
    dropshipping: 'Dropshipping',
    bsocial: 'BSocial',
    title: "It's time to start!",
    subTitle: 'Grow your online business',
    titleUpgrade: 'Upgrade plan!',
    subTitleUpgrade: 'Grow your online business',
    modal: {
      title: 'Upgrade plan',
      subTitle1List:
        'You already have one list, you can create just one list with your role. If you want to create another list upgrade your plan',
      subTitle3Lists:
        'If you need another list or need a turnkey website contact us we will be happy to help you.',
      upgrade: 'Upgrade plan',
      bookCall: 'Book a call',
      title3Lists: 'Need more lists?',
    },
    newList: 'New list',
  },
  auth: {
    forget: 'Forgot password?',
    sendLink: 'Send link',
    createPassword: 'Create password',
    createPasswordText: 'Insert your new password',
    back: 'Back',
    forgetSubtitle:
      'Insert you email address and insert your new password. You will receive a confermation email.',
    erroEmail: 'You must insert a valid email',
    welcome: 'Welcome to BDroppy!',
    fewQuestion:
      'Just a few questions to offer you the best solution to grow your online business together',
    emailSendTitle: 'Email sent',
    emailSendSubtitle:
      'You will receive an email for the confirmation of new password',
    passwordCreatedText: 'Password created, now you can login',
    goToLogin: 'Go to login',
    passwordCreatedTitle: 'Password created',
    registered: 'Not yet registered?',
    signup: 'Sign up to BDroppy',
    keep: 'Keep me signed',
    getstarted: 'Get started',
    subtitle:
      'Register for free to see the products in the catalog and discover all the features of BDroppy!',
    already: 'Already registered?',
    register: 'Register',
    continueRegister: 'Continue registration',
    loginto: 'Login to BDroppy',
    forgot: 'Forgot password?',
    login: 'Log in',
    create: 'Create your account ',
    mobile: 'Insert your mobile number',
    name: 'Name',
    email: 'Email',
    password: 'Password',
    privacy:
      'I accept [privacy1] and [privacy2] and I consent to receive updates and communications from BDroppy',
    privacy1: 'terms of use',
    privacy2: 'privacy policy',
    optional: 'Optional',
    howDidYouDiscoverBDroppy: {
      title: 'How did you discover BDroppy?',
      option1: 'Through advertising on Facebook / Instagram',
      option2: 'Through Google or other search engines',
      option3: 'On YouTube',
      option4: 'By word of mouth',
      option5: 'Through the Brandsdistribution website',
      option6: 'Other',
    },
    sidebar: {
      title:
        'Thousands of designer fashion products, a unique dashboard for your dropshipping business',
      list1: 'Dropshipping service',
      list2: 'Over 100 fashion brands in the catalogue',
      list3: 'Integrations with Amazon and eBay',
      list4: 'Plugins for Prestashop, Woocommerce and Shopify',
      list5: 'Turnkey e-commerce website solution',
      list6:
        'Soluzione sito e-commerce chiavi in mano + dropshipping integrato',
      list7:
        'BSocial: vendi sui tuoi social network preferiti, senza e-commerce',
    },
    selectCountry: 'Select your country',
    alreadySell: {
      title: 'Do you already sell online?',
      option1: 'Yes',
      option2: 'No',
      website: 'Website',
      description: 'Activity description',
    },
    revenue: {
      title: 'Qual è il tuo fatturato medio annuale',
      option1: 'Più di 100.000€',
      assistance:
        'Ti piacerebbe essere seguito da una consulente commerciale dedicata?',
    },
    selectPrefix: 'Select prefix',
    phoneNumber: 'Phone number',
    alreadyInUse: 'Email already in use',
    invalidEmail: 'Email Address is not valid.',
    insertCountry: 'To complete the registration please enter your country',
    completeSocialRegistration: 'Complete registration',
    googleLogin: 'Login with Google',
    facebookLogin: 'Login with Facebook',
    googleSignup: 'Sign up with Google',
    facebookSignup: 'Sign up with Facebook',
    enableCookies:
      'To sign in with google go to cookie settings and enable third party cookies',
    genericGoogleError: 'Logging in with google is not possible right now',
    wrongCredentials: 'Username or password is incorrect.',
    noEmailFromSocial:
      'Registration could not be completed. Please enter an email address on your Facebook account and try signing in again',
    roleExpired: 'Could not log in, please contact customer service',
    forceToResetPassword: 'Please reset password',
  },
  admin: {
    title: 'admin',
    plans: 'plans',
  },
  photoDownload: {
    select: 'Select a brand',
  },
  shippingFees: {
    title: 'Shipping fees for dropshipping orders',
    subTitle:
      'We ship to customers throughout Europe, USA and many other countries. Find out more on the shipping costs divided by geographical areas.',
    europe: 'Europe',
    title_2:
      "We'd like to inform our customers that deliveries by [span] be guaranteed for orders that are confirmed",
    span: 'Christmas will ONLY',
    subtitle_2:
      'Italia per ordini confermati entro il 18/12 con servizi express',
    subtitle_3:
      'Europa, Uk, Usa per ordini confermati entro il 16/12 con servizio express',
    exEurope: 'Extra Europe',
    euPost: 'POST - Delivery in 4-8 working days',
    euPostCol1: 'Europe*',
    euPostCol2: 'Germany, Spain',
    euPostCol3: 'Austria, Belgium, France, Netherlands',
    euPostCol4:
      'Croatia, Estonia, Lithuania, Poland, Czech Republic, Slovenia, Hungary',
    euPostCol5: 'Denmark, Latvia, Finland, Romania, Slovakia, Sweden',
    euPostCol6: 'Luxembourg, Portugal',
    euPostCol7: 'Bulgaria, Greece',
    euPostCol8: 'Malta',
    euStandard: 'STANDARD - Delivery in 3-5 working days',
    euStandardCol1: 'Austria, France, Germany',
    euStandardCol2: 'Belgio, Spain, Luxembourg, Netherlands',
    euStandardCol3: 'Croatia, Slovenia',
    euStandardCol4:
      'Bulgaria, Denmark, Estonia, Finland, Ireland, Lithuania, Latvia, Poland, Portugal, Czech Republic, Romania, Slovakia, Sweden, Hungary',
    euStandardCol5: 'Greece',
    euStandardCol6: 'Switzerland',
    euStandardCol7: 'Norway',
    euExpress: 'EXPRESS - Delivery in 2-3 working days',
    euExpressCol1: 'Italy',
    euExpressCol2:
      'Austria, Belgium, France, Germany, Netherlands, Principality of Monaco',
    euExpressCol3:
      'Denmark, Finland, Ireland, Portugal, United Kingdom (Northern Ireland only), Sweden',
    euExpressCol4:
      'Bulgaria, Cyprus, Lithuania, Latvia, Malta, Poland, Romania, Hungary, Estonia, Slovakia,  Spain, Czech Republic, Greece, Luxembourg',
    exEuStandard: 'STANDARD - Delivery in 5-10 working days',
    exEuStandardT1Col1: 'United Kingdom',
    exEuStandardT2Col1: 'Canada',
    exEuStandardT3Col1: 'USA',
    exEuExpress: 'EXPRESS - Delivery in 2-5 working days',
    exEuExpressT1Col1: 'USA, Canada',
    exEuExpressT1Col2:
      'China, Philippines, Japan, Hong Kong, Indonesia, Malaysia, Singapore, Thailand',
    exEuExpressT1Col3: 'Australia, Puerto Rico, New Zealand',
    exEuExpressT2Col1: 'United Kingdom (Excluging NI)',
    exEuExpressT3Col1: 'United Arab Emirates, Saudi Arabia',
    country: 'Country',
    weightRange: 'Weight range',
    price: 'Price',
    important: 'Important',
    importantText:
      'Shipping costs are to be considered VAT excluded and are to be paid by the buyer. For direct shipments to USA, if the order value is below 800 USD custom duties are not applied. For shipments to extra EU countries, the customs duties and fees may be applied depending on the regulations of the country of destination of the goods. Please contact your local customs office for more information.',
    importantNB:
      '*Italy, Germany, Spain (Canary Island excluded), Austria, Belgium, Bulgaria, Croatia, Estonia, Finland, France, Luxembourg, Netherlands, Czech Republic, Hungary, Slovenia, Slovakia, Ireland, Latvia, Lithuania, Poland, Romania, Denmark, Portugal, Sweden, Greece, Malta',
  },
  technicalSupport: {
    title: 'Technical support',
    subTitle1:
      'Do you need a specialist at your disposal? Choose TECHNICAL SUPPORT for a customized intervention.',
    subTitle2:
      'With our TECHNICAL SUPPORT we offer you a wide range of customized professional services designed to save you time and help you sell faster.',
    step1Title: 'What it includes',
    step1List1: 'Plugin installation',
    step1List2: 'Verification of the correct import of products',
    step1List3: 'Transmission of the first test order',
    step1List4:
      'Dedicated monitoring during the 48h following the installation',
    addToCart: 'ADD TO CART',
    goldSupport: 'GOLD SUPPORT',
    step2Thank:
      'Thank you, we received your payment. In order to take in charge your request, it’s mandatory to fill up this form:',
    website: 'Website',
    URLLoginAdmin: 'URL Login Admin',
    username: 'Username',
    password: 'Password',
    FTPAddress: 'Address',
    FTPPorta: 'Port',
    FTPProtocol: 'Protocol',
    FTPUsername: 'Username',
    FTPPassword: 'Password',
    FTPNote: 'Notes',
    continue: 'CONTINUE',
    FTPFormTitle: 'FTP ACCESS TO WEBSHOP SERVER',
    thankYouTitle: 'Thank you!',
    thankYouSubtitle:
      'We have received your request. Our IT department will contact you as soon as possible',
  },
  extraField: {
    messageHome:
      "Great news coming soon: don't miss all our communications in real time, [cta]",
    hrefHome: '/pricing',
    ctaHome: 'follow us on Instagram!',
    message:
      'Sell designer fashion in dropshipping. Ship all over Europe for only 6.90 € - [cta]',
    cta: 'DISCOVER OUR SHIPPING FEES',
    href: '/shipping-fees',
    blog: {
      img: 'https://bdroppy.com/wp-content/uploads/2022/02/dropship-bags-pierre-cardin-superdeal-blog.jpg',
      imgAlt: 'New arrivals',
      href: 'https://bdroppy.com/2022/02/09/dropship-discounted-handbags-pierre-cardin/',
    },
    shipping:
      'SPESE DI SPEDIZIONI GRATUITE PER ORDINI PARI O SUPERIORI A 100€ IN TUTTA EUROPA [icon_1]',
    shipping_2: 'E  200$ in USA  [icon_2]',
    icon_1: 'emojione:flag-for-european-union',
    icon_2: 'emojione:flag-for-united-states',
    icon_3: 'emojione:flag-for-united-kingdom',
    and: 'and [icon_3]',
  },
  shop: {
    availabilityError: 'Not enough availability for product',
    selectSize: 'Select size',
    selectColor: 'Select color',
    sizesGuide: 'Sizes guide',
    availability: 'Availability',
    description: 'description',
    yourAddress: 'Your address',
    goToCart: 'Go back to cart',
    name: 'Name',
    email: 'Email',
    streetName: 'Street name',
    zip: 'Zip',
    city: 'City',
    province: 'Province',
    country: 'Country',
    prefix: 'Prefix',
    phoneNumber: 'Phone number',
    saveAddress: 'Save address',
    yourCart: 'Your cart',
    size: 'Size',
    quantity: 'Quantity',
    totalProduct: 'Total product',
    buyItFor: 'Buy it for',
    checkout: 'Checkout',
    shipping: 'Shipping',
    terms: "I've read and accept [privacy1] and [privacy2]",
    privacy: "I've read and accept [privacy1] and [privacy2]",
    privacy1: 'terms of use',
    privacy2: 'privacy policy',
    privacy1Link: 'https://bdroppy.com/terms-and-conditions/',
    privacy2Link: 'https://bdroppy.com/privacy-policy/',
    share: 'Share',
    paymentInfo: 'Payment info',
    paypalInfo:
      'You will be redirected to PayPal payment form in order to finish payment process',
    paymentComplete: 'Thank you for your purchase!',
    insertShippingAddress: 'Insert shipping address',
    noShipping: 'Payment disabled because shipping is not available to ',
    availability0: 'Product out of stock',
    total: 'Total',
    cashback: 'Cashback',
    orderRecap: 'Order recap',
    myCollections: 'My collection',
    viewDetail: 'View detail',
    back: 'View collection',
    textCashback: 'Your cashback will be charged on your bdroppy account',
    continueShopping: 'Continue shopping',
    orderRecapBelow: 'Here below you can find your order recap',
    orderConfirmed:
      'You will soon receive an email with order confirmation and your invoice',
    orderCompleted: 'Order completed',
    orderTotal: 'Order total',
    selectQuantity: 'Select quantity',
    insertAddress: 'Insert address',
    cost: 'Cost',
    vat: 'VAT',
    noAddress: 'Fill in the address to get the total',
    continuePayment: 'Continue payment',
    addTocart: 'Add to cart',
    productAddedStore: 'Product added to store',
    productRemovedStore: 'Product removed from store',
    productMaxLimit: 'Limit of products reached',
    errorRemoveProduct: 'Error in removing the product from your wishlist',
    shareStore: 'Look at my store!',
    billingAddress: 'Indirizzo di fatturazione',
    selectSameAddress:
      "scegli se usare lo stesso indirizzo o inserirne uno nuovo al momento dell'inserimento dati carta",
    cardPayment: 'Per pagamenti con carta di credito o debito',
    sameAddress: ' Indirizzo fatturazione uguale a spedizione',
    newAddress: 'Utilizza nuovo indirizzo',
    preHeaderText: 'Discover all products on BDroppy',
    headerText: 'Take a look at my selection',
    footerText: 'View all product catalog on BDroppy',
    footerPreText: '',
    cta: 'Go to Bdroppy',
    securePayment: 'Secure payment',
    shippingInfo: 'Fast shipping from Italy',
    sameBrand: 'Same brand',
    sameBrandSub: 'Same brand text',
    similarProducts: 'Similar products',
    similarProductsSub: 'Similar products text',
    title_1: 'prodotti originali',
    title_2: "prezzi all'ingrosso",
    title_3: "Spedizioni dall'Italia",
    cashbackInfo:
      "Riceverai il cashback subito. Procedi con l'acquisto del prodotto, il cashback verrà accreditato al tuo account BDroppy.",
  },
  landing: {
    img1: 'https://bdroppy.com/wp-content/uploads/2022/05/01-gucci_EN.jpg',
    img2: 'https://bdroppy.com/wp-content/uploads/2022/05/02-karl-lagerfeld_EN.jpg',
    img3: 'https://bdroppy.com/wp-content/uploads/2022/05/03-tommy-hilfiger_EN.jpg',
    img4: 'https://bdroppy.com/wp-content/uploads/2022/05/04-guess_EN.jpg',
    img5: 'https://bdroppy.com/wp-content/uploads/2022/05/05-love-moschino_EN.jpg',
    img6: 'https://bdroppy.com/wp-content/uploads/2022/05/06-napapijri_EN.jpg',
    img7: 'https://bdroppy.com/wp-content/uploads/2022/05/07-calvin-klein_EN.jpg',
    img8: 'https://bdroppy.com/wp-content/uploads/2022/05/08-diesel_EN.jpg',
    step1: 'Scegli il prodotto',
    step2: 'Condividilo con i tuoi amici',
    step3: 'Fai la tua vendita',
    step4: 'Noi spediamo',
    step5: 'Incassa il denaro',
    discoverProducts: 'SCOPRI TUTTI I PRODOTTI',
    createAccount: 'CREA IL TUO ACCOUNT GRATUITO',
    footerTitle: 'Fai decollare le tue vendite con BDroppy!',
    footerSubTitle:
      'Oltre 120 marchi di moda firmata in pronta consegna, spedizioni rapide e sicure.',
    startNow: 'INIZIA ORA',
    findOutMore: 'SCOPRI DI PIÙ',
    turnkey: 'Vuoi una soluzione professionale chiavi in mano?',
    turnkeySubTitle:
      'Il nostro team creerà per te un sito e-commerce pronto all’uso, personalizzato e integrato con il servizio dropshipping di BDroppy.',
    preventivo: 'RICHIEDI UN PREVENTIVO',
    brandTitle:
      'E ci occupiamo anche delle spedizioni: tu vendi, noi spediamo!',
    brandSubTitle:
      'I tuoi amici, follower o clienti potranno acquistare in pochissimi click e riceveranno direttamente il prodotto in pochi giorni, grazie alle spedizioni rapide e sicure di BDroppy.',
    muchMore: 'E molti altri...',
    question: 'Il modo più facile per un guadagno extra?',
    answer: 'Vendi moda firmata con ',
    bdroppy: 'BDroppy',
    productTitle:
      'BDroppy è l’unica piattaforma che ti consente di vendere moda firmata online,',
    productTitle2: 'senza neanche bisogno di un e-commerce!',
    productSubTitle: 'Ecco alcuni dei marchi che potrai vendere subito',
    haveAChannel: 'Hai già un canale di vendita?',
    saleChannel: 'Fai crescere il tuo business online con il',
    droppshippingService: 'servizio dropshipping di BDroppy.',
    brand: 'Ecco alcuni dei marchi che potrai vendere in dropshipping:',
    earn: 'Guadagna.',
    sell: 'Vendi.',
    share: 'Condividi.',
    isFree: 'Inizia subito è gratis',
    getstarted: 'Get started',
    welcome: 'Welcome',
    shareTitle: 'Guadagna su ogni singola vendita, nessuna commissione extra.',
    shareSubTitle:
      'Con il piano BSocial potrai vendere sui tuoi social network preferiti i prodotti che vuoi condividere, in modo facile e veloce. Ti basta uno smartphone!',
    b2c: {
      imgHeader:
        'https://bdroppy.com/wp-content/uploads/2023/03/header-B2C.png',
      title_1: 'Amante della moda e del risparmio?',
      title_2:
        "Su BDroppy trovi migliaia di prodotti di moda firmata con sconti fino all'80% e non solo.",
      subtitle: '[highlight] IMMEDIATO, su ogni tuo acquisto!',
      highlight: 'Ottieni il 10% di cashback',
      footerTitle: 'Scelto da oltre [footerSpan] in tutto il mondo',
      footerSpan: '450.000 appassionati di moda',
      discoverProducts: 'Scopri tutti i prodotti',
      category_title: 'Perché Scegliere BDroppy?',
      category_p:
        'Solo su BDroppy puoi ricevere il 10% di CASHBACK su tutti i tuoi acquisti e quelli dei tuoi amici.',
      category_p_2: 'Acquista la moda a prezzi scontati e ricevilo SUBITO!',
      icon_title_1: '+120',
      icon_label_1: 'marchi originali',
      icon_title_2: '+15',
      icon_label_2: 'anni di esperienza',
      icon_title_3: '+60.000',
      icon_label_3: "spedizioni all'anno",
      createAccount: 'Crea il tuo account gratuito',
      brands_title:
        'Potrai scegliere tra migliaia di prodotti e [gradientText] e [gradientText2]',
      gradientText: 'oltre 120 marchi',
      gradientText2: 'moda firmata',
      CTAhome: 'Iscriviti subito',
    },
    b2b: {
      title_1:
        'il modo più facile per guadagnare grazie alla vendita di moda firmata. Vendi e guadagna [title_2] su ogni singola vendita',
      title_2: 'fino al 30%',
      subtitle: 'Crea il tuo [highlight] online con BDroppy!',
      highlight: 'business',
      footerTitle: 'Scelto da oltre [footerSpan] in tutto il mondo',
      footerSpan: '450.000 appassionati di moda',
      discoverProducts: 'Scopri tutti i prodotti',
      category_title: "Cos'è BDroppy?",
      category_p:
        "Bdroppy è la prima piattaforma di social-ecommerce di moda firmata, dove puoi trovare i tuoi marchi preferiti con sconti fino all'80% su abbigliamento, calzature, borse e accessori",
      icon_label_1: 'Alti margini di guadagni',
      icon_label_2: '+120 marchi di moda',
      icon_label_3: 'Spedizioni rapide e sicure',
      createAccount: 'Crea il tuo account gratuito',
      brands_title:
        'Potrai scegliere tra migliaia di prodotti e [gradientText] e [gradientText2]',
      gradientText: 'oltre 120 marchi',
      gradientText2: 'moda firmata',
      CTAhome: 'Iscriviti subito',
      products:
        'Migliaia di prodotti di moda, infinite possibilità di business',
      shareAndEarn: 'Condividi. Vendi. Guadagna',
      li_1: 'Un catalogo con oltre 100 marchi di moda firmata',
      li_2: 'Prodotti firmati 100% originali',
      li_3: 'Spedizioni rapide e affidabili in tutto il mondo',
      li_4: 'Nessuna commissione sul venduto',
      li_5: 'Suggerimenti e consigli di vendita settimanali',
      li_6: 'Foto in alta definizione e materiale pubblicitario gratuito',
      haveAChannel:
        'Con Bdroppy vendi direttamente su Amazon, Ebay, WooCommerce, Prestashop, Shopify, Squarespace e condividi sui tuoi canali social preferiti.',
      saleChannel:
        'Con Bdroppy vendi direttamente su Amazon, Ebay, WooCommerce, Prestashop, Shopify, Squarespace e condividi sui tuoi canali social preferiti.',
      droppshippingService:
        'Con Bdroppy vendi direttamente su Amazon, Ebay, WooCommerce, Prestashop, Shopify, Squarespace e condividi sui tuoi canali social preferiti.',
      earn_1: 'Guadagna fino a',
      earn_2: 'Guadagna fino a',
      earn_3: 'Guadagna fino a',
      earn_4: 'Guadagna fino a',
      earn_5: 'Guadagna fino a',
      earn_6: 'Guadagna fino a',
      earn_7: 'Guadagna fino a',
      earn_8: 'Guadagna fino a',
    },
  },
  onboarding: {
    bsocial: {
      save: 'Save',
      payPalTitle: 'Paypal',
      payPalDescription1:
        'Insert your email of paypal account in order to activate paypal payment',
      payPalDescription2: 'Not a Paypal client yet?',
      payPalLink: 'Create an account here',
      paymentMethodTitle: 'Payment method',
      paymentMethodDescription: 'Select payment method',
      confirmationEmail: 'Confirmation Email',
      paypalEmail: 'PayPal email',
      generalSetting: 'General setting',
      generalSettingSubtitle: 'Update your list name, currency and language ',
      currency: 'Currency',
      language: 'Language',
      storeName: 'Shop name',
      ecreditInfo:
        'You have selected to receive your money as a credit into your purse. You can request the transfer of your earnings to your bank upon reaching 100€.',
      BDroppyCredit: 'Credit on BDroppy',
      storeNameWarning:
        'If you change the name of your store you will need to reshare your store link',
      storeNameWarningTitle: 'Warning',
      cancel: 'Cancel',
    },
    turnkey: {
      updateCatalog: 'Update catalog',
      updateCatalogTitle: 'You are moving connection',
      confirm: 'Confirm',
      websiteConf: 'Website configuration',
      nextStep: 'Next step',
      selectListSubtitle:
        "Select the list you want to attach to your website. If you don't have any list create your first list!",
      selectListInfo:
        ' If you select a list already connected with a channel you will change the connection with turnkey webstore',
      channelConnected: 'Canale collegato :',
      createList: 'Create list',
      cancel: 'Cancel',
      newList: 'New list',
      configurationDone: 'Configuration done',
      subtitleStep3: "Now we'll take care of everything!",
      email: 'You will receive an email when the website is ready',
      selectProducts: ' Select products to add on your list',
      browseCatalog: 'Browse catalog',
      goToSetting: 'Go to settings',
      manageList: 'Manage settings of your list',
      goBack: 'Go back',
      connectToShopify: 'Connect to Shopify',
      shopifySubtitle:
        'You will be redirect on Shopify in order to connect the channel. you need to wait a few minutes for the syn of products',
      insertShopName: 'Inserisci il nome del tuo negozio Shopify',
      connectChannel: 'Connect channel',
      titleModal: 'How to start',
      finishConfiguration:
        'Finish your configuration now, select the list for your website and start your business',
      selectList: 'Select list',
      steps: {
        step_1: '1 - Choose your list',
        stepDescripiton_1:
          'Select the list you want to connect with website. If you have no list create the first one. ',
        step_2: '2 - Connect to Shopify',
        stepDescripiton_2:
          'You will be redirect to channel section, insert your Shopify shop url and connect the list.',
        step_3: '3 - Tell us your preferences',
        stepDescripiton_3:
          "Now we'll take care of everything, you will receive an email when the website is ready",
        step_4: '4  - Manage your list',
        stepDescripiton_4:
          'Select products and manage the setting of your list, create markup rules',
        step_5: '5 - Start selling',
        stepDescripiton_5:
          "Now we'll take care of everything, you will receive an email when the website is ready. Start selling!",
      },
    },
  },
  formErrors: {
    required: '[fieldName] is required',
    validEmail: '[fieldName] must be a valid E-mail',
    length: '[fieldName] must be [len] character',
    minLength: '[fieldName] must have [min] characters at least',
    maxLength: '[fieldName] must have a maximum of [max] characters',
    invalid: '[fieldName] not valid',
    onlyNumber: '[fieldName] must be only numbers',
    minValue: '[fieldName] must be at least [minValue]',
    maxAmount: 'The amount requested is higher than your earnings', // Ritiro crediti bdroppy
  },
  cart: {
    title: 'Cart',
    goToCatalogue: 'Go to catalogue',
    empty: 'Your cart is empty',
    noItem: 'You have no item on your cart',
    browseCatalogue:
      'Browse our product catalogue and find out our products and the new arrivals of this week',
    productRemoved: 'Product removed from your cart',
    addToCart: 'Compralo subito',
    viewPricesPublic: "Vedi prezzi all'ingrosso",
    sellNow: 'Vendilo online',
    addToList: 'Aggiungilo alla lista',
    cart: 'Cart',
    productAdded: 'Product added to your cart',
    addedToList: 'Now select a list ',
    checkout: 'checkout',
    viewCart: 'View cart',
    selectSize: 'Select size',
    selectQuantity: 'Select quantity',
    continueShopping: 'Continue shopping',
    messageSize: 'If you want to add to import do not select size',
    productDetail: 'Product detail',
    viewSize: 'View avaibility',
    share: 'Share and earn',
    productRemove: 'Product removed',
    freeMessage: 'Sembra che tu non abbia ancora un piano attivo. Che aspetti?',
    catalog: 'Catalog',
    freeMessageTitle: 'Ops...',
    freeMessageSubtitle: 'Start selling and earning now!',
    ctaPlan: 'Activate a plan',
    warningNoSize: 'Select a size to continue',
    insertShopname: 'Setting social',
    insertDataChannel:
      'Non hai inserito correttamente tutti i dati per attivare il canale social',
    settingSocial: 'Inserisci il nome del tuo shop',
    goToSettings: 'Go to settings',
    noProductToShare:
      'You have no product to share, add one to twenty products and start selling',
    viewCatalog: 'Choose products',
    addProductToshare: 'Add product to your store',
    alertNoProduct: 'No product on your store',
    shareYourStore: 'Share',
    views: 'Views',
    likes: 'Likes',
    addToStore: 'Add to store',
    removeFromStore: 'Remove from store',
    shareAndEarn: 'Share and earn',
    shareAndEarnFree: 'Share and earn',
    youNeed: 'You are [number] away from free shipping',
    shippingFree: 'Free shipping fees',
    viewPrice: "Vedi prezzi all'ingrosso",
    cashback: 'Cashback',
    steps: {
      checkout: 'Checkout',
      days: 'Days literal',
      goBack: 'Back to address',
      shippingAddress: 'Shipping address',
      goPayment: 'Go to payment',
      securePayment: 'Secure payment',
      continueShopping: 'Continue shopping',
      cart: 'My cart',
      or: 'or',
      sameProducts: 'Similar products',
      sameProductsSubTitle: 'You might also be interested',
      freeShipping: 'Free shipping',
      shippingNotes: 'Enter notes for the carrier',
      billingAddress: 'Billing address',
    },
  },
  public_catalog: {
    login: 'Login',
    register: 'Register',
    services: 'Services',
    brands: 'Brands',
    blog: 'Blog',
  },
  brands: {
    title: 'The best brands for your online business',
    text1: 'Over 100 top Italian and international fashion brands',
    text2:
      'Create your free account and access the dashboard to consult the entire catalogue and exclusive prices reserved for our resellers.',
    text3:
      'You can select the products to add to your personal catalogue and set the prices to',
    text4: 'get the most out of your online sales!',
  },
  menuItem: {
    home: 'Home',
    allproducts: 'View all product',
    viewAll: 'View all categories',
    viewAllProd: 'View all',
    man: 'Man',
    woman: 'Woman',
    clothing: 'Clothing',
    sweatShirts: 'Sweatshirts',
    dresses: 'Dresses',
    jeans: 'Jeans',
    shirts: 'Shirts',
    leggings: 'Leggings',
    sweaters: 'Sweaters',
    tops: 'Tops',
    skirts: 'Skirts',
    tShirts: 'T-shirts',
    trousers: 'Trousers',
    jackets: 'Jackets',
    tracksuitPants: 'Tracksuit pants',
    rucksacks: 'Rucksacks',
    underwear: 'Underwear',
    shoes: 'Shoes',
    flatShoes: 'Flat shoes',
    sneakers: 'Sneakers',
    laceUp: 'Lace up',
    mocassins: 'Mocassins',
    boots: 'Boots',
    ankleBoots: 'Ankle boots',
    decolletè: 'Décolletté',
    sandals: 'Sandals',
    wedges: 'Wedges',
    slipOn: 'Slip on',
    accessories: 'Accessories',
    sunglasses: 'Sunglasses',
    belts: 'Belts',
    necklaces: 'Necklaces',
    watches: 'Watches',
    wallets: 'Wallets',
    eyeglasses: 'Eyeglasses',
    scarves: 'Scarves',
    bags: 'Bags',
    crossBodyBags: 'Crossbody bags',
    travelBags: 'Travel bags',
    pochette: 'Clutch bags',
    shoulderBags: 'Shouldr bags',
    handBags: 'Handbags',
    briefCases: 'Briefcases',
    shoppingBag: 'Shopping bag',
    brands: 'Brands',
    reseller: 'Become a reseller',
  },
  wishlist: {
    title: 'Your wishlist ',
    remove: 'Remove',
    shareAndEarn: 'Share',
    buy: 'Buy',
    title_1: 'Add products',
    subtitle_1: 'Add products to your wishlist',
    title_2: 'Share your wishlist',
    subtitle_2: 'Share your wishlist with your friend',
    title_3: 'Cashback',
    subtitle_3: 'Receive 10% cashback on all products',
    noProducts: 'Your wishlist is empty',
    noProductToShare: 'Add products and start sharing',
    viewCatalog: 'View all products',
    addProducts: 'Add products on your wishlist and share it with your friends',
    addProductModal:
      'You have no product to share, add one to twenty products and start selling',
    alertNoProduct: 'No products on your wishlist',
    addProductsCTA: 'Add products',
    confirm: 'Confirm',
    changeName: 'Change name',
    yourWishlist: 'Your wishlist',
    wishlistName: 'Give your wishlist a name',
  },
});
export default translations;
