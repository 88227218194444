import { defaultTranslations } from 'hooks/useTranslation';

export function translations(state = null, action) {
  switch (action.type) {
    case 'SET_TRANSLATION':
      return action.payload;
    default:
      return state || defaultTranslations();
  }
}

export function locale(state = 'en_US', action) {
  switch (action.type) {
    case 'SET_LOCALE':
      return action.payload;
    default:
      return state;
  }
}

export function description(state = 'en_US', action) {
  switch (action.type) {
    case 'SET_LANG_DESCRIPTION':
      return action.payload;
    default:
      return state;
  }
}

export function shippingCountry(state = 'IT', action) {
  switch (action.type) {
    case 'SET_COUNTRY_SHIPPING':
      return action.payload;
    default:
      return state;
  }
}

export function currency(state = 'Euro', action) {
  switch (action.type) {
    case 'SET_CURRENCY':
      return action.payload;
    default:
      return state;
  }
}
